<template>
  <top-header></top-header>
  <div id="content" v-if="dataReady">
    <Modal
      :name="aStudentName"
      v-show="isModalVisible"
      @close="closeModal"
      @delete1="deleteStudent"
    />
    <ModalPW
      :name="aStudentName"
      v-show="isModalPWVisible"
      @close="closePWModal"
      @reset1="resetStudentPW"
    />
    <div id="title">{{ className }} Roster</div>
    <div id="title2">
      <router-link
        :to="{
          name: 'progress',
          query: { classID: classID, class: className }
        }"
      >
        View {{ className }} Progress<img
          alt="Progress"
          src="../../assets/images/arrow_black_right.png"
        />
      </router-link>
    </div>

    <div v-if="error" class="error">{{ error }}</div>
    <div id="content">
      <form @submit.prevent="pressed">
        <input
          type="text"
          v-model="student_name"
          placeholder="First Name Only"
        />&nbsp;&nbsp;
        <n-button @click="handleClick" ghost type="primary" color="blue"
          >Add Student</n-button
        >
      </form>
    </div>

    <div id="content2">
    <table v-if="students.length > 0">
      <tr>
        <th>Name</th>
        <th>Password</th>
        <th>Last Login</th>
        <th>Total Time <br />HH:MM</th>
        <th>Language</th>
        <th colspan="3" width="40px">&nbsp;</th>
      </tr>
      <tr v-for="aStudent in students" :key="aStudent.id">
        <td style="text-align: left;">
          {{ aStudent.name }}
        </td>
        <td>{{ aStudent.pw }}</td>
        <td>{{ aStudent.lastlog }}</td>
        <td>{{ aStudent.hourslog }}:{{ aStudent.minuteslog }}</td>
        <td>English</td>
        <td>
          <a
            href="#"
            @click="
              showModalPW(aStudent.name, aStudent.myDocID, aStudent.email)
            "
          >
            <img
              alt="Reset Password"
              src="../../assets/images/resetpw_icon.png"
            />
          </a>
        </td>
        <td>
          <router-link
            :to="{
              name: 'editstudent',
              query: {
                docID: aStudent.myDocID,
                name: aStudent.name,
                studentID: aStudent.id,
                email: aStudent.email,
                classID: classID,
                class: className,
                //language: aStudent.language
              }
            }"
          >
            <img alt="Edit" src="../../assets/images/edit_icon.png" />
          </router-link>
        </td>
        <td>
          <a
            href="#"
            @click="showModal(aStudent.name, aStudent.myDocID, aStudent.email)"
          >
            <img alt="Delete" src="../../assets/images/trash.png" />
          </a>
        </td>
      </tr>
    </table>
    </div>
  </div>

  <div id="content" v-else>
    Data loading, please wait...
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';
import Modal from '@/components/Modal.vue';
import ModalPW from '@/components/ModalPW.vue';
import { NButton } from 'naive-ui';

export default {
  name: 'RosterView',
  components: {
    'top-header': TopHeader,
    'n-button': NButton,
    Modal,
    ModalPW
  },
  data() {
    return {
      error: '',
      dataReady: false,
      students: [],
      classID: this.$route.query.classID,
      className: this.$route.query.class,
      //rosterLanguage: this.$route.query.language,
      classCode: this.$store.state.user.code,
      isModalVisible: false,
      isModalPWVisible: false,
      aStudentName: '',
      aStudentID: '',
      aStudentEmail: '',
      student_name: ''
    };
  },
  methods: {
    showModal(theStudName, theStudID, theStudEmail) {
      this.isModalVisible = true;
      this.aStudentName = theStudName;
      this.aStudentID = theStudID;
      this.aStudentEmail = theStudEmail;
    },
    showModalPW(theStudName, theStudID, theStudEmail) {
      this.isModalPWVisible = true;
      this.aStudentName = theStudName;
      this.aStudentID = theStudID;
      this.aStudentEmail = theStudEmail;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    closePWModal() {
      this.isModalPWVisible = false;
    },
    async resetStudentPW() {
      this.isModalPWVisible = false;
      try {
        var db = firebase.firestore();

        let docRef = db.collection('users').doc(this.aStudentID);
        let aDoc = await docRef.get();
        let myOldPassword = aDoc.data().password;

        var rosterRef = await db.collection('classes').doc(this.classID);
        await db.runTransaction(async transaction => {
          var rosterDoc = await transaction.get(rosterRef);
          if (!rosterDoc.exists) {
            throw 'Document does not exist!';
          }
          var newRoster = rosterDoc.data().roster;
          const objIndex = newRoster.findIndex(
            obj => obj.user === this.aStudentEmail
          );
          newRoster[objIndex].first = true;
          newRoster[objIndex].oldPW = myOldPassword;
          await transaction.update(rosterRef, { roster: newRoster });
        });

        await db
          .collection('users')
          .doc(this.aStudentID)
          .set(
            {
              password: 'Not Set'
            },
            { merge: true }
          );

        this.students = [];
        var studentsRef = await db.collection('users');
        var theStudents = await studentsRef
          .where('classID', '==', this.classID)
          .orderBy('name');
        let snapshot = await theStudents.get();
        let lastLogin = 'none';
        let hoursLogged = 0;
        let minutesLogged = 0;
        snapshot.forEach(doc => {
          if (doc.data().logintime) {
            lastLogin = new Date(doc.data().logintime).toLocaleDateString(
              'en-US'
            );
          } else lastLogin = 'none';
          if (doc.data().timelogged) {
            let minutes = Math.floor(doc.data().timelogged / 60000);
            hoursLogged = Math.floor(minutes / 60);
            minutesLogged = minutes % 60;
          } else {
            hoursLogged = 0;
            minutesLogged = 0;
          }
          this.students.push({
            name: doc.data().name,
            lastlog: lastLogin,
            hourslog: hoursLogged,
            minuteslog: minutesLogged,
            email: doc.data().email,
            //language: doc.data().language,
            pw: doc.data().password,
            id: doc.data().id,
            myDocID: doc.id
          });
        }, this);
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    },
    async deleteStudent() {
      this.isModalVisible = false;
      try {
        var db = firebase.firestore();
        await db
          .collection('users')
          .doc(this.aStudentID)
          .delete();

        var rosterRef = await db.collection('classes').doc(this.classID);
        await db.runTransaction(async transaction => {
          var rosterDoc = await transaction.get(rosterRef);
          if (!rosterDoc.exists) {
            throw 'Document does not exist!';
          }
          var newRoster = rosterDoc.data().roster;
          const objIndex = newRoster.findIndex(
            obj => obj.user === this.aStudentEmail
          );
          newRoster.splice(objIndex, 1);
          await transaction.update(rosterRef, { roster: newRoster });
        });

        this.students = [];
        var studentsRef = await db.collection('users');
        var theStudents = await studentsRef
          .where('classID', '==', this.classID)
          .orderBy('name');
        let snapshot = await theStudents.get();
        let lastLogin = 'none';
        let hoursLogged = 0;
        let minutesLogged = 0;
        snapshot.forEach(doc => {
          if (doc.data().logintime) {
            lastLogin = new Date(doc.data().logintime).toLocaleDateString(
              'en-US'
            );
          } else lastLogin = 'none';
          if (doc.data().timelogged) {
            let minutes = Math.floor(doc.data().timelogged / 60000);
            hoursLogged = Math.floor(minutes / 60);
            minutesLogged = minutes % 60;
          } else {
            hoursLogged = 0;
            minutesLogged = 0;
          }
          this.students.push({
            name: doc.data().name,
            lastlog: lastLogin,
            hourslog: hoursLogged,
            minuteslog: minutesLogged,
            email: doc.data().email,
            //language: doc.data().language,
            pw: doc.data().password,
            id: doc.data().id,
            myDocID: doc.id
          });
        }, this);
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    },
    async handleClick() {
      var randNum = Math.floor(Math.random() * 999999);
      var myEmail = 'c_' + this.classCode + '_' + randNum + '@computales.com';
      try {
        if (this.student_name.length == 0)
          throw 'Please enter the student name';
        if (this.student_name.length > 14)
          throw 'The name must be less than 15 characters';
        else {
          var db = firebase.firestore();

          var classDocRef = db.collection('classes').doc(this.classID);
          var aClassDoc = await classDocRef.get();
          if (aClassDoc.exists) {
            let aClassRoster = aClassDoc.data().roster;
            if (aClassRoster) {
              for (var i = 0; i < aClassRoster.length; i++) {
                if (aClassRoster[i].name == this.student_name) {
                  throw 'That name has already been used. Please enter a unique name.';
                }
              }
            }
          }

          const user = await firebase
            .auth()
            .createUserWithEmailAndPassword(myEmail, 'b3Az17');
          await db
            .collection('users')
            .doc(user.user.uid)
            .set({
              name: this.student_name,
              code: this.classCode,
              classID: this.classID,
              email: myEmail,
              password: 'Not Set',
              //language: this.rosterLanguage
            });

          var rosterRef = await db.collection('classes').doc(this.classID);
          rosterRef.update({
            roster: firebase.firestore.FieldValue.arrayUnion({
              first: true,
              name: this.student_name,
              user: myEmail
            })
          });

          this.student_name = '';
          this.error = '';

          this.students = [];
          var studentsRef = await db.collection('users');
          var theStudents = await studentsRef
            .where('classID', '==', this.classID)
            .orderBy('name');
          let snapshot = await theStudents.get();
          let lastLogin = 'none';
          let hoursLogged = 0;
          let minutesLogged = 0;
          snapshot.forEach(doc => {
            if (doc.data().logintime) {
              lastLogin = new Date(doc.data().logintime).toLocaleDateString(
                'en-US'
              );
            } else lastLogin = 'none';
            if (doc.data().timelogged) {
              let minutes = Math.floor(doc.data().timelogged / 60000);
              hoursLogged = Math.floor(minutes / 60);
              minutesLogged = minutes % 60;
            } else {
              hoursLogged = 0;
              minutesLogged = 0;
            }
            this.students.push({
              name: doc.data().name,
              lastlog: lastLogin,
              hourslog: hoursLogged,
              minuteslog: minutesLogged,
              email: doc.data().email,
              //language: doc.data().language,
              pw: doc.data().password,
              id: doc.data().id,
              myDocID: doc.id
            });
          }, this);
        }
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    }
  },
  async mounted() {
    var db = firebase.firestore();
    try {
      var studentsRef = await db.collection('users');
      var theStudents = await studentsRef
        .where('classID', '==', this.$route.query.classID)
        .orderBy('name');
      let snapshot = await theStudents.get();
      let lastLogin = 'none';
      let hoursLogged = 0;
      let minutesLogged = 0;
      snapshot.forEach(doc => {
        if (doc.data().logintime) {
          lastLogin = new Date(doc.data().logintime).toLocaleDateString(
            'en-US'
          );
        } else lastLogin = 'none';
        if (doc.data().timelogged) {
          let minutes = Math.floor(doc.data().timelogged / 60000);
          hoursLogged = Math.floor(minutes / 60);
          minutesLogged = minutes % 60;
        } else {
          hoursLogged = 0;
          minutesLogged = 0;
        }
        this.students.push({
          name: doc.data().name,
          lastlog: lastLogin,
          hourslog: hoursLogged,
          minuteslog: minutesLogged,
          email: doc.data().email,
          //language: doc.data().language,
          pw: doc.data().password,
          id: doc.data().id,
          myDocID: doc.id
        });
      }, this);
      this.dataReady = true;
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<style scoped>
#content {
  text-align: center;
  margin: auto;
  padding: 5px;
  max-width: 1150px;
  font-size: 16px;
}

#title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: 120px;
}

#title2 {
  text-align: center;
  font-size: 18px;
  margin: auto;
  color: blue;
}

#content2 {
  text-align: center;
  margin: auto;
  padding: 0px;
  font-size: 18px;
  height:500px;
  max-width:900px;
  overflow:auto;
}

table {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid black;
  border-spacing: 5px;
  text-align: center;
  border-collapse: collapse;
  background-color: #afdafd;
}

td {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 6px;
}

th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 3px;
  background-color: #52b1ff;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

ul {
  margin: 0 auto;
}

.error {
  color: red;
  font-size: 18px;
}

select,
input {
  width: 200px;
  padding: 5px;
  margin: 5px;
  font-size: 18px;
}
button {
  width: 150px;
  height: 35px;
  font-size: 18px;
}

a {
  color: blue;
  padding: 5px 10px;
  text-align: center;
  text-decoration: underline;
  font-size: 16px;
}

a:hover {
  font-size: 16px;
  color: blue;
  text-decoration: underline;
}
</style>
