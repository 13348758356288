<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle">
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            X
          </button>
        </header>

        <section v-show="!surveySubmitted" class="modal-body" id="modalDescription">
          <form @submit.prevent="pressed">
          <div class="ques-body" id="ques1" name="ques1">
            How engaged are your students while using CompuTales?
          </div>
          <div class="rate">
            <input type="radio" id="star5" v-model="rate1" value="5" />
            <label for="star5" title="text">5 stars</label>
            <input type="radio" id="star4" v-model="rate1" value="4" />
            <label for="star4" title="text">4 stars</label>
            <input type="radio" id="star3" v-model="rate1" value="3" />
            <label for="star3" title="text">3 stars</label>
            <input type="radio" id="star2" v-model="rate1" value="2" />
            <label for="star2" title="text">2 stars</label>
            <input type="radio" id="star1" v-model="rate1" value="1" />
            <label for="star1" title="text">1 star</label>
          </div>
          <div class="ques-body" id="ques2" name="ques2">
            How helpful is the  curriculum?
          </div>
          <div class="rate">
            <input type="radio" id="star6" v-model="rate2" value="5" />
            <label for="star6" title="text">5 stars</label>
            <input type="radio" id="star7" v-model="rate2" value="4" />
            <label for="star7" title="text">4 stars</label>
            <input type="radio" id="star8" v-model="rate2" value="3" />
            <label for="star8" title="text">3 stars</label>
            <input type="radio" id="star9" v-model="rate2" value="2" />
            <label for="star9" title="text">2 stars</label>
            <input type="radio" id="star10" v-model="rate2" value="1" />
            <label for="star10" title="text">1 star</label>
          </div>
          <div class="ques-body" id="ques3" name="ques3">
            How is the quality of the computational activities?
          </div>
          <div class="rate">
            <input type="radio" id="star11" v-model="rate3" value="5" />
            <label for="star11" title="text">5 stars</label>
            <input type="radio" id="star12" v-model="rate3" value="4" />
            <label for="star12" title="text">4 stars</label>
            <input type="radio" id="star13" v-model="rate3" value="3" />
            <label for="star13" title="text">3 stars</label>
            <input type="radio" id="star14" v-model="rate3" value="2" />
            <label for="star14" title="text">2 stars</label>
            <input type="radio" id="star15" v-model="rate3" value="1" />
            <label for="star15" title="text">1 star</label>
          </div>
          <div class="ques-body" id="ques4" name="ques4">
            How well is literacy integrated?
          </div>
          <div class="rate">
            <input type="radio" id="star16" v-model="rate4" value="5" />
            <label for="star16" title="text">5 stars</label>
            <input type="radio" id="star17" v-model="rate4" value="4" />
            <label for="star17" title="text">4 stars</label>
            <input type="radio" id="star18" v-model="rate4" value="3" />
            <label for="star18" title="text">3 stars</label>
            <input type="radio" id="star19" v-model="rate4" value="2" />
            <label for="star19" title="text">2 stars</label>
            <input type="radio" id="star20" v-model="rate4" value="1" />
            <label for="star20" title="text">1 star</label>
          </div>
          <label class="text-body" for="ques5">What areas can we improve?</label>
          <textarea class="text-body2" id="ques5" v-model="ques5" rows="4" cols="30">
          </textarea>
          <label class="text-body3" for="ques6">What do you like about CompuTales?</label>
          <textarea class="text-body2" id="ques6" v-model="ques6" rows="4" cols="30">
          </textarea>
          <footer class="modal-footer">
            <button
              type="button"
              class="btn-green"
              @click="submitSurvey"
              aria-label="Submit Survey"
            >
              Submit Survey
            </button>
          </footer>
          </form>
        </section> 
        <section v-show="surveySubmitted" class="modal-body" id="modalDescription">
          Thanks for taking our survey!<br/><br/>
          <button
              type="button"
              class="btn-green"
              @click="close"
              aria-label="Close Window"
            >
              Close
            </button>
        </section>    
      </div>
    </div>
  </transition>
</template>

<script>
import { firebase } from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';

export default {
  name: 'ModalSurvey',
  methods: {
    close() {
      this.$emit('close');
    },
    async submitSurvey() {    
      try {
          var db = firebase.firestore();
          await db.collection('mail').add({
          to: 'support@computales.com',
          message: {
            subject: 'CompuTales Survey',
            html:
              'From: ' +
              this.name +
              '<br><br>' +
              'Email: ' +
              this.email +
              '<br><br>' +
              'How engaged are your students while using CompuTales? <br>'+ this.rate1 +
              '<br><br>How helpful is the curriculum? <br>'+ this.rate2 +
              '<br><br>How is the quality of the computational activities? <br>'+ this.rate3 +
              '<br><br>How well is literacy integrated? <br>'+ this.rate4 +
              '<br><br>What areas can we improve? <br>'+ this.ques5 +
              '<br><br>What do you like about CompuTales? <br>'+ this.ques6
          }
        });
        this.surveySubmitted = true;
      }
      catch (err) {
        console.log(err);
        this.error = err;
      }
    }
  },
  data() {
    return {
      surveySubmitted: false,
      rate1: 'none',
      rate2: 'none',
      rate3: 'none',
      rate4: 'none',
      ques5: '',
      ques6: '',
      name: this.$store.state.user.name,
      email: this.$store.state.user.email,
      error: ''
    };
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.modal-header,
.modal-footer {
  padding-bottom: 25px;
  display: flex;
}

.modal-header {
  color: #4aae9b;
  justify-content: space-between;
  font-size: 24px;
  position: sticky;
  top: 0;
  z-index: 999;
}

.modal-footer {
  align-items: center;
  justify-content: center;
}
.modal-body {
  position: relative;
  padding: 5px 10px;
  font-size: 24px;
  width: 600px;
  height: 540px;
}

.ques-body {
  text-align: center;
  font-size: 20px;
  width: 560px;
}

.text-body {
  display: inline-block;
  text-align: center;
  font-size: 20px;
  width: 560px;
}

.text-body2 {
  font-size: 20px;
}

.text-body3 {
  display: inline-block;
  text-align: center;
  font-size: 20px;
  width: 560px;
  margin-top: 25px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 24px;
  padding-bottom: 10px;
  padding-right: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
  font-size: 24px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.7s ease;
}

.rate {
    display: inline-block;
    height: 55px;
    padding: 0 0px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}

textarea {
  width: 90%;
  height: 100px;
  padding: 6px 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}
</style>
