import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import firebase from 'firebase/app';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDn3pRz0GbfZvy1u0DCBrXjqZHvhpCiqQM',
  authDomain: 'computales.firebaseapp.com',
  databaseURL: 'https://computales.firebaseio.com',
  projectId: 'computales',
  storageBucket: 'computales.appspot.com',
  messagingSenderId: '631314136619',
  appId: '1:631314136619:web:0dccc25e6bbe06d886f46e'
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let app;
if (!app) {
  app = createApp(App)
    .use(store)
    .use(router)
    .mount('#app');
}
