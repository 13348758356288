<template>
  <public-header></public-header>
  <div id="spacer"></div>
  <div id="content">
    <div class="div1">
      <h1 id="mainhead">
        Curriculum
      </h1>
      <div id="box3">
        <p id="descript1">
        Our curriculum is designed to make teaching computational thinking as easy as
        possible for early learning teachers. Provided teacher guides, have everything 
        needed for a engaging learning experience. Warm-up and closing activities, as 
        well as discussion questions, help the teacher to provide context and checks 
        for understanding. The curriculum is designed to work equally well in a whole 
        group or self-paced environment. Each standards-aligned chapter targets a
        specific computational topic and can be completed in about an hour.
        After completing CompuTales, your students will have a solid foundation in 
        computational thinking without sacrificing your time needed for literacy instruction.
        </p>
      </div>
      <div id="box4">
          <img
          id="gang"
          src="../assets/images/mc_self.png"
          style="margin-top: 30px;"
        />
      </div>
    </div>
    <div id="div3">
      <div id="content3">
        <h1 id="mainhead">
          Benefits
        </h1>
        <div id="box1">
          <ul class="features">
            <li class="features">
              Easy to install the app and set up classes
            </li>
            <li class="features">
              Students are engaged by the story and characters
            </li>
            <li class="features">
              Blended learnng approach combines app and offline workbook
            </li>
            <li class="features">
              Age appropriate computing and literacy activities
            </li>
            <li class="features">
              Covers all computational thinking topics including coding
            </li>
            <li class="features">
              Easy for teachers to implement in less than an hour per week
            </li>
            <li class="features">
              No computer science knowledge needed
            </li>
            <li class="features">
              Student progress tracking with teacher dashboard
            </li>
            <li class="features">
              Aligned to CSTA K-2 computer science standards
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="div4">
      <h1 id="mainhead">Frequently Asked Questions</h1>
      <details>
        <summary>Do I need to know coding and computer science?</summary>
        <div>
          CompuTales can be done by any teacher with very little preparation.
          Each chapter is designed to be done in about an hour. Students are
          able to complete the chapters at their own pace.
        </div>
      </details>
      <details>
        <summary>Isn't too much screen time bad for students?</summary>
        <div>
          CompuTales is <em>active</em> not <em>passive</em> screen time. After
          students complete the online portion of the lesson, they reinforce the
          learning offline in their CompuTales workbook.
        </div>
      </details>
      <details>
        <summary>Won't this take time away from teaching kids to read?</summary>
        <div>
          CompuTales integrates literacy topics with engaging stories that teach
          computer science. There is animated narration for early readers.
          Age-appropriate literacy activities are embedded in each chapter.
        </div>
      </details>
      <details>
        <summary>Is CompuTales a coding program?</summary>
        <div>
          Computer Science is much more than coding. CompuTales is a unique
          product that teaches all the CSTA K-2 computer science standards,
          including coding, while at the same time reinforcing literacy.
        </div>
      </details>
    </div>
    <div class="footer">
      <div id="content4">
        <div id="brandinfo">
          <img alt="CompuTales Logo" src="../assets/images/logo3.png" /><br />
          CompuTales helps students to learn the building blocks of Computer
          Science with a fun and interactive story. Narrated for emerging
          readers, and aligned to CSTA standards for K-2.
        </div>
        <div id="aboutinfo">
          <p id="header-heading">
            <router-link :to="{ name: 'about' }" class="link-footer"
              >About</router-link
            >
          </p>
          <ul class="no-bullets">
            <li>Our Why</li>
            <li>How It Works</li>
            <li>Features</li>
          </ul>
        </div>
        <div id="educatorinfo">
          <p id="header-heading">
            <router-link :to="{ name: 'educators' }" class="link-footer"
              >Educators</router-link
            >
          </p>
          <ul class="no-bullets">
            <li>Curriculum</li>
            <li>Benefits</li>
            <li>FAQs</li>
          </ul>
        </div>
        <div id="storeinfo">
          <p id="header-heading">
            <router-link :to="{ name: 'store' }" class="link-footer"
              >Store</router-link
            >
          </p>
          <ul class="no-bullets">
            <li>Workbooks</li>
            <li>Subscribe</li>
            <li>&nbsp;</li>
          </ul>
        </div>
        <div id="connectinfo">
          <p id="header-heading">
            <router-link :to="{ name: 'contact' }" class="link-footer"
              >Connect With Us</router-link
            >
          </p>
          <ul class="no-bullets">
            <li>Contact</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PublicHeader from '@/components/Public-Header';
export default {
  name: 'EducatorsView',
  components: {
    'public-header': PublicHeader
  }
};
</script>

<style scoped>
@media only screen and (min-width: 901px) {
  div.div1 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  div.div1 {
    font-size: 14px;
  }
}

@media only screen and (min-width: 901px) {
  div.div2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  div.div2 {
    font-size: 14px;
  }
}

@media (min-width: 901px) {
  h1 {
    font-size: 36px;
  }
}

@media (max-width: 900px) {
  h1 {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  div#connectinfo {
    display: none;
  }
}

@media (max-width: 1300px) {
  div#storeinfo {
    display: none;
  }
}

@media (max-width: 1000px) {
  div#educatorinfo {
    display: none;
  }
}

@media (max-width: 800px) {
  div#aboutinfo {
    display: none;
  }
}

@media (max-width: 1400px) {
  img#gang {
    display: none;
  }
}

div.div1 {
  margin-top: 86px;
  text-align: center;
  display: inline-block;
  width: 60%;
}

div.div2 {
  text-align: center;
  display: inline-block;
  width: 60%;
}

#div3 {
  display: grid;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  margin: 0px;
}

#div4 {
  text-align: center;
  display: inline-block;
  width: 50%;
  margin-bottom: 300px;
}

.footer {
  background-color: rgb(50, 50, 50);
  clear: both;
  position: relative;
  height: 200px;
  margin-top: -200px;
}

#content {
  text-align: center;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}

#content2 {
  max-width: 1150px;
}

#content3 {
  max-width: 1150px;
}

#content4 {
  width: 70%;
  color: white;
  padding-top: 20px;
  margin: auto;
}

#brandinfo {
  font-size: 14px;
  width: 320px;
  float: left;
  text-align: left;
  line-height: 1.6;
}

#aboutinfo {
  width: 140px;
  text-align: left;
  line-height: 1.6;
  margin-left: 50px;
  display: inline-block;
}

#educatorinfo {
  width: 140px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#storeinfo {
  width: 140px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#connectinfo {
  width: 220px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#header-heading {
  font-size: 16px;
  padding: 0;
  margin-bottom: 0px;
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
  margin-left: 8px;
  font-size: 14px;
}

#mainhead {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 10px;
  margin: 10px;
}

#descript1 {
  text-align: left;
  display: flex;
  line-height: 1.8;
  margin-top: 0px;
}

#checkhead {
  font-size: 32px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0px;
}

.link {
  font-weight: 500;
  color: blue;
  list-style: none;
  text-decoration: none;
  font-size: 20px;
  transition: 0.5s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}

.link-footer {
  font-weight: 500;
  color: white;
  list-style: none;
  text-decoration: none;
  font-size: 18px;
  transition: 0.5s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}

.link:hover {
  color: blue;
  border-color: #00afea;
}

.link-footer:hover {
  color: white;
  border-color: #00afea;
}

ul.features {
  float: left;
  margin-left: 0;
  padding-right: 0;
  margin-top: 0px;
  text-align: left;
}

li.features {
  position: relative;
  padding: 0 0 0 0px;
  margin: 0px;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: left;
  margin-top: 0.8em;
}

#box1 {
  font-size: 20px;
  padding: 10px;
  max-width: 740px;
  height: 430px;
  background-color: rgb(203, 252, 205);
  border-radius: 25px;
  border: 4px solid #01a50f;
  line-height: 1.5;
  margin-top: 10px;
}

#box3 {
  font-size: 20px;
  padding: 10px;
  max-width: 900px;
  float: left;
  line-height: 1.5;
}

#box4 {
  width: 200px;
  float: right;
}

body {
  background-color: #000;
  font-family: 'Open Sans', sans-serif;
}

.container h1 {
  color: rgb(0, 0, 0);
  text-align: center;
}

details {
  background-color: #e4e4e4;
  color: rgb(0, 0, 0);
  font-size: 20px;
}

summary {
  padding: 0.5em 1.3rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  transition: height 1s ease;
  font-weight: bold;
}

summary::-webkit-details-marker {
  display: none;
}

summary:after {
  content: '\002B';
}

details[open] summary {
  border-bottom: 1px solid #aaa;
  margin-bottom: 0.5em;
}

details[open] summary:after {
  content: '\00D7';
}

details[open] div {
  padding: 0.5em 1em;
  text-align: left;
  padding-bottom: 28px;
}
</style>
