import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Educators from '../views/Educators.vue';
import Store from '../views/Store.vue';
import Contact from '../views/Contact.vue';
import LogSelect from '../views/LogSelect.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Signup from '../views/Signup.vue';
import Quote from '../views/Quote.vue';
import Grant from '../views/Grant.vue';
import RegisterComplete from '../views/RegisterComplete.vue';
import Instructions from '../views/Instructions.vue';
import Classes from '../views/secure/Classes.vue';
import Curriculum from '../views/secure/Curriculum.vue';
import PD from '../views/secure/PD.vue';
import ChangePass from '../views/secure/ChangePass.vue';
import Roster from '../views/secure/Roster.vue';
import Progress from '../views/secure/Progress.vue';
import EditClass from '../views/secure/EditClass.vue';
import EditStudent from '../views/secure/EditStudent.vue';
import StudentDetail from '../views/secure/StudentDetail.vue';
import ResetPW from '../views/ResetPW.vue';
import ResetComplete from '../views/ResetComplete.vue';
import CurriculumGuide from '../views/CurriculumGuide.vue';
import NotesOverview from '../views/secure/notes/NotesOverview.vue';
import Notes1 from '../views/secure/notes/Notes1.vue';
import Notes2 from '../views/secure/notes/Notes2.vue';
import Notes3 from '../views/secure/notes/Notes3.vue';
import Notes4 from '../views/secure/notes/Notes4.vue';
import Notes5 from '../views/secure/notes/Notes5.vue';
import Notes6 from '../views/secure/notes/Notes6.vue';
import Notes7 from '../views/secure/notes/Notes7.vue';
import Notes8 from '../views/secure/notes/Notes8.vue';
import Notes9 from '../views/secure/notes/Notes9.vue';
import Notes10 from '../views/secure/notes/Notes10.vue';
import Notes11 from '../views/secure/notes/Notes11.vue';
import Notes12 from '../views/secure/notes/Notes12.vue';
import Notes13 from '../views/secure/notes/Notes13.vue';
import Notes14 from '../views/secure/notes/Notes14.vue';
import Notes15 from '../views/secure/notes/Notes15.vue';
import Notes16 from '../views/secure/notes/Notes16.vue';
import Notes17 from '../views/secure/notes/Notes17.vue';
import Notes18 from '../views/secure/notes/Notes18.vue';

import firebase from '@firebase/app';
import '@firebase/auth';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/educators',
    name: 'educators',
    component: Educators
  },
  {
    path: '/store',
    name: 'store',
    component: Store
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/logselect',
    name: 'logselect',
    component: LogSelect
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/quote',
    name: 'quote',
    component: Quote
  },
  {
    path: '/grant',
    name: 'grant',
    component: Grant
  },
  {
    path: '/registercomplete',
    name: 'registercomplete',
    component: RegisterComplete
  },
  {
    path: '/curriculumguide',
    name: 'curriculumguide',
    component: CurriculumGuide
  },
  {
    path: '/instructions',
    name: 'instructions',
    component: Instructions
  },
  {
    path: '/classes',
    name: 'classes',
    component: Classes,
    meta: { requiresAuth: true }
  },
  {
    path: '/curriculum',
    name: 'curriculum',
    component: Curriculum,
    meta: { requiresAuth: true }
  },
  {
    path: '/pd',
    name: 'pd',
    component: PD,
    meta: { requiresAuth: true }
  },
  {
    path: '/changepass',
    name: 'changepass',
    component: ChangePass,
    meta: { requiresAuth: true }
  },
  {
    path: '/roster',
    name: 'roster',
    component: Roster,
    meta: { requiresAuth: true }
  },
  {
    path: '/progress',
    name: 'progress',
    component: Progress,
    meta: { requiresAuth: true }
  },
  {
    path: '/editclass',
    name: 'editclass',
    component: EditClass,
    meta: { requiresAuth: true }
  },
  {
    path: '/editstudent',
    name: 'editstudent',
    component: EditStudent,
    meta: { requiresAuth: true }
  },
  {
    path: '/studentdetail',
    name: 'studentdetail',
    component: StudentDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/resetpw',
    name: 'resetpw',
    component: ResetPW
  },
  {
    path: '/resetcomplete',
    name: 'resetcomplete',
    component: ResetComplete
  },
  {
    path: '/notesoverview',
    name: 'notesoverview',
    component: NotesOverview,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes1',
    name: 'notes1',
    component: Notes1,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes2',
    name: 'notes2',
    component: Notes2,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes3',
    name: 'notes3',
    component: Notes3,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes4',
    name: 'notes4',
    component: Notes4,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes5',
    name: 'notes5',
    component: Notes5,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes6',
    name: 'notes6',
    component: Notes6,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes7',
    name: 'notes7',
    component: Notes7,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes8',
    name: 'notes8',
    component: Notes8,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes9',
    name: 'notes9',
    component: Notes9,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes10',
    name: 'notes10',
    component: Notes10,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes11',
    name: 'notes11',
    component: Notes11,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes12',
    name: 'notes12',
    component: Notes12,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes13',
    name: 'notes13',
    component: Notes13,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes14',
    name: 'notes14',
    component: Notes14,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes15',
    name: 'notes15',
    component: Notes15,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes16',
    name: 'notes16',
    component: Notes16,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes17',
    name: 'notes17',
    component: Notes17,
    meta: { requiresAuth: true }
  },
  {
    path: '/notes18',
    name: 'notes18',
    component: Notes18,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;
  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router;
