<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="curriculum_content">
    <span id="back">
      <router-link to="/curriculum"
        ><img
          alt="Edit"
          src="../../../assets/images/arrow_black_left.png"
        />Back to Chapters</router-link
      ></span
    >
  </div>
  <div id="curriculum_content">
    <div id="chp_content">
      <h2>Teacher Notes - Chapter 10</h2>
      <strong>CS Standard:</strong> Develop programs with sequences and simple
      loops, to express ideas or address a problem. (CSTA 1A-AP-10)
      <p><strong>Lesson Flow</strong></p>
      <p>Warm-up Video: Coming Soon</p>
      <p>
        Warm Up: Ask students for step-by-step directions on how to get to the
        door. Ask students to break apart any large instructions, like "Walk to
        the door," into smaller instructions like "Take one step forward." (10
        minutes)
      </p>
      <p>
        Online Work: Students read chapter ten and complete the chapter reading
        and computing activities to earn three stars. (25 minutes)
      </p>
      <p>
        Offline Work: Students complete
        <a href="wrkbk/ch10.pdf" target="_blank">Chapter Ten</a> in their
        CompuTales workbook. (20 minutes)
      </p>
      <p>
        Closing: Ask students what is the difference between an algorithm and a
        program. (5 minutes)
      </p>
      <p>
        Literacy Discussion: What is special about Qwerty? (5 minutes)
      </p>
      <p>
        <strong>Resources</strong><br />
        Story Summary: As they are travelling down code creek, they meet the
        last Champion Qwerty (the keyboard). Each Champion explains to Martin
        what they do in a computers. To explain what computers do, they ask
        Martin to give directions that they will follow.
      </p>
      <p>
        Sample Computing Activities:
      </p>
      <p>
        Sample Reading Activities:
      </p>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
export default {
  name: 'NotesTen',
  components: {
    'top-header': TopHeader
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

#curriculum_content {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 1100px;
  text-align: left;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 10px;
}

#chp_content {
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-size: 18px;
}

#back {
  width: 50%;
  float: left;
  text-align: left;
}

a {
  color: blue;
  text-align: center;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

h2 {
  text-align: center;
  font-size: 20px;
  padding: 0px;
}
</style>
