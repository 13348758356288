<template>
  <header>
    <nav>
      <div class="branding">
        <router-link to="/"
          ><img alt="CompuTales Logo" src="../assets/images/logo2.png" />
        </router-link>
      </div>
      <ul v-show="!mobile" class="navigation">
        <li>
          <router-link class="link" :to="{ name: 'about' }">About</router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: 'educators' }"
            >Educators</router-link
          >
        </li>
        <li>
          <router-link class="link" :to="{ name: 'store' }">Help Us</router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: 'contact' }"
            >Contact Us</router-link
          >
        </li>
      </ul>
      <div v-show="!mobile" class="buttongroup">
        <router-link class="button2" to="/signup">Sign Up</router-link>
        <router-link class="button1" to="/logselect">Login</router-link>
      </div>
      <div class="icon">
        <img
          @click="toggleMobileNav"
          v-show="mobile"
          src="../assets/images/hamburger.png"
          :class="{ 'icon-active': mobileNav }"
        />
      </div>
      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav">
          <li>
            <router-link class="link" :to="{ name: 'about' }"
              >About</router-link
            >
          </li>
          <li>
            <router-link class="link" :to="{ name: 'educators' }"
              >Educators</router-link
            >
          </li>
          <li>
            <router-link class="link" :to="{ name: 'store' }"
              >Help Us</router-link
            >
          </li>
          <li>
            <router-link class="link" :to="{ name: 'contact' }"
              >Contact Us</router-link
            >
          </li>
          <li>
            <router-link class="button2a" to="/signup">Sign Up</router-link>
          </li>
          <li>
            <router-link class="button1a" to="/logselect">Login</router-link>
          </li>
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Public-Header',
  data() {
    return {
      scrolledNav: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null
    };
  },
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1300) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    }
  }
};
</script>

<style scoped>
header {
  background-color: rgb(195, 224, 255);
  z-index: 99;
  width: 100%;
  position: fixed;
  transition: 0.5s ease all;
  color: #fff;
  top: 0px;
  left: 0px;
}

nav {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 4px 0px;
  transition: 0.5s ease all;
  width: 75%;
  margin: 0 auto;
}

ul,
.link {
  font-weight: 500;
  color: rgb(0, 0, 0);
  list-style: none;
  text-decoration: none;
}

li {
  padding: 8px;
  margin-right: 14px;
}

.link {
  font-size: 18px;
  transition: 0.5s ease all;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
}

.link:hover {
  color: blue;
  border-color: #00afea;
}

a.router-link-exact-active {
  color: blue;
  border-color: #00afea;
}

.branding {
  display: flex;
  align-items: center;
  padding: 8px 0px;
}

img {
  transition: 0.5s ease all;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 24px;
  height: 100%;
}

.buttongroup {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 24px;
  height: 100%;
}

.icon-active {
  transform: rotate(180deg);
}

.dropdown-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  max-width: 250px;
  height: 100%;
  background-color: rgb(195, 224, 255);
  top: 0;
  left: 0;
}

.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: 1s ease all;
}

.mobile-nav-enter-from,
.mobile-nav-leave-to {
  transform: translateX(-250px);
}

.mobile-nav-enter-to {
  transform: translateX(0);
}

.button1 {
  color: #075ee1;
  background-color: white;
  padding: 9px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 12px;
  margin-left: 20px;
  border: 1px solid #075ee1;
}

.button1a {
  color: #075ee1;
  background-color: white;
  padding: 8px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 12px;
  margin-left: 0px;
}

.button2 {
  background-color: #4ca44a;
  color: white;
  padding: 9px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 12px;
  margin-left: 20px;
  border: 1px solid #4ca44a;
}

.button2a {
  background-color: #4ca44a;
  color: white;
  padding: 8px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 12px;
  margin-left: 0px;
}

a.button1:hover,
a.button1.router-link-exact-active {
  background-color: blue;
  font-size: 20px;
  color: #fff;
}

a.button2:hover,
a.button2.router-link-exact-active {
  background-color: green;
  font-size: 20px;
  color: #fff;
}
</style>
