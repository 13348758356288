<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content" v-if="dataReady">
    <Modal
      :name="aClassName"
      :isClass="true"
      v-show="isModalVisible"
      @close="closeModal"
      @delete1="deleteClass"
    />
    <ModalSurvey
      v-show="isModalSurveyVisible"
      @close="closeSurveyModal"
    />
    <div>
      <a href="#" @click="showSurveyModal()">Take our survey</a>
    </div>
    <div v-if="error" class="error">{{ error }}</div>
    <div id="content" v-if="classes.length < 28">
      <form @submit.prevent="pressed">
        <input
          type="text"
          v-model="class_name"
          placeholder="Class Name"
        />&nbsp;&nbsp;
        <n-button @click="handleClick" ghost type="primary" color="blue"
          >Add Class</n-button
        >
      </form>
    </div>

    <div id="content2">
    <table v-if="classes.length != 0">
      <tr>
        <th>Class Name</th>
        <th>Students</th>
        <th>Access Code</th>
        <th>Language</th>
        <th colspan="2" width="40px">&nbsp;</th>
      </tr>
      <tr v-for="aClass in classes" :key="aClass.id">
        <td style="text-align: left;">
          <router-link
            :to="{
              name: 'progress',
              query: { 
                classID: aClass.id,
                class: aClass.name
              }
            }"
          >
            {{ aClass.name }}
          </router-link>       
        </td>
        <td>
          <router-link
            :to="{
              name: 'roster',
              query: {
                classID: aClass.id,
                class: aClass.name,
                language: aClass.language
              }
            }"
          >
            {{ aClass.studs }}
          </router-link>
        </td>
        <td>{{ aClass.code }}</td>
        <td>{{ aClass.language }}</td>
        <td>
          <router-link
            :to="{
              name: 'editclass',
              query: {
                classID: aClass.id,
                class: aClass.name
              }
            }"
          >
            <img alt="Edit" src="../../assets/images/edit_icon.png" />
          </router-link>
        </td>
        <td>
          <a href="#" @click="showModal(aClass.name, aClass.id)">
            <img alt="Delete" src="../../assets/images/trash.png" />
          </a>
        </td>
      </tr>
    </table>
    </div>
  </div>
  <div id="content" v-else>
    Data loading, please wait...
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';
import Modal from '@/components/Modal.vue';
import ModalSurvey from '@/components/Modal-Survey.vue';
import { NButton } from 'naive-ui';

export default {
  name: 'ClassesView',
  components: {
    'top-header': TopHeader,
    'n-button': NButton,
    Modal,
    ModalSurvey
  },
  data() {
    return {
      error: '',
      dataReady: false,
      classes: [],
      aClassName: '',
      aClassID: '',
      class_name: '',
      isModalVisible: false,
      isModalSurveyVisible: false
    };
  },
  methods: {
    showModal(theClassName, theClassID) {
      this.isModalVisible = true;
      this.aClassName = theClassName;
      this.aClassID = theClassID;
    },
    showSurveyModal() {
      this.isModalSurveyVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    closeSurveyModal() {
      this.isModalSurveyVisible = false;
    },
    async deleteClass() {
      this.isModalVisible = false;
      try {
        var db = firebase.firestore();
        await db
          .collection('classes')
          .doc(this.aClassID)
          .delete();
      } catch (err) {
        console.log(err);
        this.error = err;
      }

      try {
        let deleteStuds = await db
          .collection('users')
          .where('classID', '==', this.aClassID)
          .get();
        for (const doc of deleteStuds.docs) {
          await db
            .collection('users')
            .doc(doc.id)
            .delete();
        }

        this.class_name = '';
        this.error = '';
        this.classes = [];
        var classesRef = await db.collection('classes');
        var theClasses = await classesRef
          .where('code', '==', this.$store.state.user.code)
          .orderBy('name');
        let snapshot = await theClasses.get();
        snapshot.forEach(doc => {
          let numStuds = 0;
          if (doc.data().roster) numStuds = doc.data().roster.length;
          this.classes.push({
            code: doc.data().code,
            name: doc.data().name,
            studs: numStuds,
            id: doc.id,
            language: doc.data().language
          });
        }, this);
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    },
    async handleClick() {
      try {
        if (this.class_name.length == 0)
          throw 'Please enter a name for the class';
        if (this.class_name.length > 19)
          throw 'Class name must be less than 20 characters';
        else {
          var db = firebase.firestore();
          await db.collection('classes').add({
            code: this.$store.state.user.code,
            name: this.class_name,
            language: 'English',
            chapters9: [
              {
                chapter: 'Chapter 1',
                active: true
              },
              {
                chapter: 'Chapter 2',
                active: true
              },
              {
                chapter: 'Chapter 3',
                active: true
              },
              {
                chapter: 'Chapter 4',
                active: true
              },
              {
                chapter: 'Chapter 5',
                active: true
              },
              {
                chapter: 'Chapter 6',
                active: true
              },
              {
                chapter: 'Chapter 7',
                active: true
              },
              {
                chapter: 'Chapter 8',
                active: true
              },
              {
                chapter: 'Chapter 9',
                active: true
              }
            ],
            chapters18: [
              {
                chapter: 'Chapter 10',
                active: true
              },
              {
                chapter: 'Chapter 11',
                active: true
              },
              {
                chapter: 'Chapter 12',
                active: true
              },
              {
                chapter: 'Chapter 13',
                active: true
              },
              {
                chapter: 'Chapter 14',
                active: true
              },
              {
                chapter: 'Chapter 15',
                active: true
              },
              {
                chapter: 'Chapter 16',
                active: true
              },
              {
                chapter: 'Chapter 17',
                active: true
              },
              {
                chapter: 'Chapter 18',
                active: true
              }
            ]
          });

          this.class_name = '';
          this.error = '';

          this.classes = [];
          var classesRef = await db.collection('classes');
          var theClasses = await classesRef
            .where('code', '==', this.$store.state.user.code)
            .orderBy('name');
          let snapshot = await theClasses.get();
          snapshot.forEach(doc => {
            let numStuds = 0;
            if (doc.data().roster) numStuds = doc.data().roster.length;
            this.classes.push({
              code: doc.data().code,
              name: doc.data().name,
              studs: numStuds,
              id: doc.id,
              language: doc.data().language
            });
          }, this);
        }
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    }
  },
  async mounted() {
    var db = firebase.firestore();
    try {
      var classesRef = await db.collection('classes');
      var theClasses = await classesRef
        .where('code', '==', this.$store.state.user.code)
        .orderBy('name');
      let snapshot = await theClasses.get();
      snapshot.forEach(doc => {
        let numStuds = 0;
        if (doc.data().roster) numStuds = doc.data().roster.length;
        this.classes.push({
          code: doc.data().code,
          name: doc.data().name,
          studs: numStuds,
          id: doc.id,
          language: doc.data().language
        });
      }, this);

      this.dataReady = true;
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<style scoped>
#spacer {
  height: 100px;
}

#content {
  text-align: center;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  max-width: 1150px;
}

#content2 {
  text-align: center;
  margin: auto;
  padding: 0px;
  font-size: 18px;
  height:500px;
  max-width:900px;
  overflow:auto;
}

#title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: auto;
  padding: 5px;
}

table {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid black;
  border-spacing: 5px;
  text-align: center;
  border-collapse: collapse;
  background-color: #afdafd;
}

td {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 6px;
}

th {
  top:0;
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  background-color: #52b1ff;
  position: sticky;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

ul {
  margin: 0 auto;
}

.error {
  color: red;
  font-size: 18px;
  margin-bottom: 0px;
}

select,
input {
  width: 300px;
  padding: 5px;
  margin: 5px;
  font-size: 18px;
}
button {
  width: 150px;
  height: 35px;
  font-size: 18px;
}

a {
  color: blue;
  padding: 5px 10px;
  text-align: center;
  text-decoration: underline;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: underline;
}
</style>
