<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content" v-if="dataReady">
    <div id="title">{{ className }} Progress</div>
    <div id="title2">
      <router-link
        :to="{
          name: 'roster',
          query: { classID: classID, class: className }
        }"
        >View {{ className }} Roster<img
          alt="Roster"
          src="../../assets/images/arrow_black_right.png"
      /></router-link>
    </div>
    <div id="content2" v-if="students.length > 0">
      <table>
        <tr>
          <th>Name</th>
          <th>Ch 1</th>
          <th>Ch 2</th>
          <th>Ch 3</th>
          <th>Ch 4</th>
          <th>Ch 5</th>
          <th>Ch 6</th>
          <th>Ch 7</th>
          <th>Ch 8</th>
          <th>Ch 9</th>
          <th>Ch 10</th>
          <th>Ch 11</th>
          <th>Ch 12</th>
          <th>Ch 13</th>
          <th>Ch 14</th>
          <th>Ch 15</th>
          <th>Ch 16</th>
          <th>Ch 17</th>
          <th>Ch 18</th>
        </tr>
        <tr v-for="aStudent in students" :key="aStudent.id">
          <td style="text-align: left;">
            <router-link
              :to="{
                name: 'studentdetail',
                query: {
                  studentID: aStudent.id,
                  classID: classID,
                  class: className
                }
              }"
            >
              {{ aStudent.name }}
            </router-link>
          </td>
          <td v-if="aStudent.ch1">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch2">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch3">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch4">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch5">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch6">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch7">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch8">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch9">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch10">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch11">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch12">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch13">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch14">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch15">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch16">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch17">Y</td>
          <td v-else>&nbsp;</td>
          <td v-if="aStudent.ch18">Y</td>
          <td v-else>&nbsp;</td>
        </tr>
      </table>
    </div>
  </div>
  <div id="content" v-else>
    Data loading, please wait...
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';

export default {
  name: 'ProgressView',
  components: {
    'top-header': TopHeader
  },
  data() {
    return {
      dataReady: false,
      students: [],
      className: this.$route.query.class,
      classID: this.$route.query.classID,
      classCode: this.$store.state.user.code
    };
  },
  async mounted() {
    var db = firebase.firestore();
    try {
      var studentsRef = await db.collection('users');
      var theStudents = await studentsRef
        .where('classID', '==', this.$route.query.classID)
        .orderBy('name');
      let snapshot = await theStudents.get();
      snapshot.forEach(doc => {
        this.students.push({
          name: doc.data().name,
          id: doc.id,
          ch1: doc.data().read1_4 && doc.data().compute1_4,
          ch2: doc.data().read2_4 && doc.data().compute2_4,
          ch3: doc.data().read3_4 && doc.data().compute3_4,
          ch4: doc.data().read4_4 && doc.data().compute4_4,
          ch5: doc.data().read5_4 && doc.data().compute5_4,
          ch6: doc.data().read6_4 && doc.data().compute6_4,
          ch7: doc.data().read7_4 && doc.data().compute7_4,
          ch8: doc.data().read8_4 && doc.data().compute8_4,
          ch9: doc.data().read9_4 && doc.data().compute9_4,
          ch10: doc.data().read10_4 && doc.data().compute10_4,
          ch11: doc.data().read11_4 && doc.data().compute11_4,
          ch12: doc.data().read12_4 && doc.data().compute12_4,
          ch13: doc.data().read13_4 && doc.data().compute13_4,
          ch14: doc.data().read14_4 && doc.data().compute14_4,
          ch15: doc.data().read15_4 && doc.data().compute15_4,
          ch16: doc.data().read16_4 && doc.data().compute16_4,
          ch17: doc.data().read17_4 && doc.data().compute17_4,
          ch18: doc.data().read18_4 && doc.data().compute18_4
        });
      }, this);
      this.dataReady = true;
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

#content {
  text-align: center;
  margin: auto;
  padding: 5px;
  max-width: 1150px;
  font-size: 16px;
}

#title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: auto;
}

#title2 {
  text-align: center;
  font-size: 18px;
  margin: auto;
}

#content2 {
  text-align: center;
  margin: auto;
  padding: 0px;
  font-size: 18px;
  height:500px;
  max-width:1100px;
  overflow:auto;
}

table {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  border: 1px solid black;
  border-spacing: 5px;
  text-align: center;
  border-collapse: collapse;
  background-color: #afdafd;
}

td {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 2px;
}

th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  background-color: #52b1ff;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

ul {
  margin: 0 auto;
}

a {
  color: blue;
  padding: 5px 10px;
  text-align: center;
  text-decoration: underline;
  font-size: 16px;
}

a:hover,
a.router-link-exact-active {
  font-size: 16px;
  color: blue;
  text-decoration: underline;
}
</style>
