<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <ModalActivity
    :img_url="aURL"
    :theTitle="aTitle"
    v-show="isModalVisible"
    @close="closeModal"
  />
  <div id="curriculum_content">
    <span id="back">
      <router-link to="/curriculum"
        ><img
          alt="Edit"
          src="../../../assets/images/arrow_black_left.png"
        />Back to Chapters</router-link
      ></span
    >
  </div>
  <div id="curriculum_content">
    <div id="chp_content">
      <h2>Teacher Notes - Chapter 13</h2>
      <strong>CS Standard:</strong> Decompose (break down) the steps needed to
      solve a problem into a precise sequence of instructions. (CSTA 1A-AP-11)
      <p><strong>Lesson Flow</strong></p>
      <p>Warm-up Video: Coming Soon</p>
      <p>
        Warm Up: Ask students to list the steps to make a peanut butter and
        jelly sandwich. (10 minutes)
      </p>
      <p>
        Online Work: Students read chapter thirteen and complete the chapter
        reading and computing activities to earn three stars. (25 minutes)
      </p>
      <p>
        Offline Work: Students complete
        <a href="wrkbk/ch13.pdf" target="_blank">Chapter Thirteen</a> in their
        CompuTales workbook. (20 minutes)
      </p>
      <p>
        Closing: Go back to the steps to make a peanut butter and jelly
        sandwich. Explain that breaking down a task into smaller parts is called
        decomposition. (5 minutes)
      </p>
      <p>
        Literacy Discussion: What does MotherBoard tell Martin and his friends
        to find? (5 minutes)
      </p>
      <p><strong>Resources</strong></p>
      <p>
        Story Summary: Martin and the Champions arrive back at MotherBoard. She
        explains that to unlock the Portal, they need to put together the pieces
        of the key. To help find the key pieces, the Champions can connect
        together and become Master Computer. Then, Martin can use Master
        Computer to program a path to the key pieces.
      </p>
      <p>
        Sample Computing Activities:
        <a
          id="modal_link"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp13_2',
              'Decomposing Problems'
            )
          "
        >
          One
        </a>
      </p>
      <p>
        Sample Reading Activities:
      </p>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import ModalActivity from '@/components/Modal-Activity.vue';
export default {
  name: 'NotesThirteen',
  components: {
    'top-header': TopHeader,
    ModalActivity
  },
  data() {
    return {
      isModalVisible: false,
      aURL: '',
      aTitle: ''
    };
  },
  methods: {
    showModal(theURL, theTitle) {
      this.isModalVisible = true;
      this.aURL = theURL;
      this.aTitle = theTitle;
    },
    closeModal() {
      this.isModalVisible = false;
      this.aURL = '';
    }
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

#curriculum_content {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 1100px;
  text-align: left;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 10px;
}

#chp_content {
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-size: 18px;
}

#back {
  width: 50%;
  float: left;
  text-align: left;
}

a {
  color: blue;
  text-align: center;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

h2 {
  text-align: center;
  font-size: 20px;
  padding: 0px;
}
</style>
