<template>
  <div id="content">
    <img alt="CompuTales Logo" src="../assets/images/logo2.png" />
    <h2>Quick Set-up Guide</h2>
    <div id="instruct">
      <p>
        <b>Step 1:</b>&nbsp;<a href="/login" target="_blank">Login</a>
        to CompuTales using the email and password you provided.
      </p>
      <p>
        <b>Step 2:</b> Under the <b>Classes</b> tab, click
        <b>Create New Class</b> and add your class information.
      </p>
      <p>
        <b>Step 3:</b> Click on your new class and use <b>Add New Student</b> to
        add the students to the class. Use only your students <b>first name</b>.
        Make note of your <b>access code</b>.
      </p>
      <p><b>Step 4:</b> Add the book app to your <b>student devices</b>.</p>
      <u>ChromeBook Set-up</u>
      <ul>
        <li>
          On a student Chromebook, Launch Chrome and navigate to
          book.computales.com.
        </li>
        <li>Click on the <b>three dots</b> at the top right of the browser.</li>
        <li>
          Hover over the <b>More Tools</b> menu and then select
          <b>Create Shortcut.</b>
        </li>
        <li>
          Change the name of the shortcut to CompuTales and check the "Open as
          Window" box, and then click
          <b>Create.</b> The CompuTales icon will appear on the Chromebook
          desktop.
        </li>
      </ul>
      <u>iPad Set-up</u>
      <ul>
        <li>
          On a student iPad, open Safari and navigate to book.computales.com.
        </li>
        <li>
          Tap the icon featuring a right-pointing arrow coming out of a box
          along the top of the Safari window to open a drop-down menu.
        </li>
        <li>Tap <b>Add to Home Screen.</b></li>
        <li>
          Enter the name <b>CompuTales</b> for the shortcut using the on-screen
          keyboard and tap
          <b>Add.</b>
        </li>
        <li>
          The CompuTales icon will appear on the Home Screen of the iPad.
        </li>
      </ul>
      <p>
        <b>Step 5:</b> On the student device, click the CompuTales icon and
        enter your access code from step 3. The app is now linked to your
        teacher account and ready for students. Maximimize the window size by
        clicking the square in the upper right corner of the window.
      </p>
      <p>
        <b>Step 6:</b> Click on the
        <a href="/curriculumguide">Curriculum Guide</a> to get started!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstructionsView'
};
</script>

<style scoped>
.error {
  color: red;
  font-size: 18px;
}
input {
  width: 400px;
  padding: 15px;
  margin: 10px;
  font-size: 20px;
}

button {
  width: 400px;
  height: 50px;
  font-size: 100%;
}

#content {
  text-align: center;
}

#instruct {
  width: 750px;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-size: 20px;
}
</style>
