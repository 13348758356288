<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="curriculum_content">
    <span id="back">
      <router-link to="/curriculum"
        ><img
          alt="Edit"
          src="../../../assets/images/arrow_black_left.png"
        />Back to Chapters</router-link
      ></span
    >
  </div>
  <div id="curriculum_content">
    <div id="chp_content">
      <h2>Teacher Notes - Overview</h2>
      CompuTales is a unique journey into computational thinking and literacy
      for students in Kindergaren through 2nd grade. It was carefullly designed
      to integrate literacy while introducing students to the computational
      thinking skills that will set the foundation for later exploration into
      computer science. It is recommended to complete CompuTales one chapter per
      week, throughout the school year. The online and offline components can be
      completed in approximately one hour each week.
      <p>
        <b>Step 1:</b>&nbsp; Provide a device for each student. Ask students to
        go to the CompuTales app and press the Start button. During first login,
        students will be asked to choose a picture password.
      </p>
      <p>
        <b>Step 2:</b>&nbsp; Assign the chapter to be completed for the week.
        Every activity and chapter in CompuTales is narrated, so students can
        complete individually, in small groups, or whole group. Students must
        complete the chapters in order, including all the activities, in order
        to proceed.
      </p>
      <p>
        <b>Step 3:</b>&nbsp; Students begin by reading the assigned chapter.
        Play buttons are provided to assist early readers.
      </p>
      <p>
        <b>Step 4:</b>&nbsp; After reading the chapter, students access the
        computing and reading activities by clicking on the chapter again.
      </p>
      <p>
        <b>Step 5:</b>&nbsp; After each set of activities is completed, a star
        is placed on the chapter block. When a student receives all three stars,
        the online portion of the chapter is complete.
      </p>
      <p>
        <b>Step 6:</b>&nbsp; After completing the online portion, students
        complete the same chapter offline in their CompuTales workbook.
      </p>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
export default {
  name: 'NotesOverview',
  components: {
    'top-header': TopHeader
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

#curriculum_content {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 1100px;
  text-align: left;
  font-size: 15px;
}

#chp_content {
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-size: 18px;
}

#back {
  width: 50%;
  float: left;
  text-align: left;
}

a {
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

h2 {
  text-align: center;
  font-size: 20px;
  padding: 0px;
}
</style>
