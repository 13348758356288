<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <ModalActivity
    :img_url="aURL"
    :theTitle="aTitle"
    v-show="isModalVisible"
    @close="closeModal"
  />
  <div id="curriculum_content">
    <span id="back">
      <router-link to="/curriculum"
        ><img
          alt="Edit"
          src="../../../assets/images/arrow_black_left.png"
        />Back to Chapters</router-link
      ></span
    >
  </div>
  <div id="curriculum_content">
    <div id="chp_content">
      <h2>Teacher Notes - Chapter 1</h2>
      <strong>CS Standard:</strong> Model daily processes by creating and
      following algorithms (sets of step-by-step instructions) to complete
      tasks. (CSTA 1A-AP-08)

      <p><strong>Lesson Flow</strong></p>

      <p>Warm-up Video: Coming Soon</p>
      <p>
        Warm Up: Review vocabulary word with students: Algorithm
        <br />"Algorithm - Say it with me: Al-go-ri-thm." Discuss definition: A
        list of steps that you follow to complete a task. Give students examples
        fo everyday algorithms (e.g. Make your bed). (5 minutes)
      </p>
      <p>
        Online Work: Students read chapter one and complete the chapter reading
        and computing activities to earn three stars. (25 minutes)
      </p>
      <p>
        Offline Work: Students complete
        <a href="wrkbk/ch1.pdf" target="_blank">Chapter One</a> in their
        CompuTales workbook. (20 minutes)
      </p>
      <p>
        Closing: Ask students what was their favorite part about the lesson. Ask
        students to give examples of things they do that follow an algorithm. (5
        minutes)
      </p>
      <p>
        Literacy Discussion: Why does Martin want a computer? (5 minutes)
      </p>

      <p><strong>Resources</strong></p>
      <p>
        Story Summary: Martin and his family members are introduced. Martin's
        sister and mom won't won't let Martin use their computer. He wishes he
        had his own computer.
      </p>
      <p>
        Sample Computing Activities:
        <a
          id="modal_link"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp1_1',
              'Everyday Algorithms'
            )
          "
        >
          One</a
        >
        |
        <a
          id="modal_link"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp1_2',
              'Everyday Algorithms'
            )
          "
        >
          Two</a
        >
      </p>
      <p>
        Sample Reading Activities:
      </p>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import ModalActivity from '@/components/Modal-Activity.vue';
export default {
  name: 'NotesOne',
  components: {
    'top-header': TopHeader,
    ModalActivity
  },
  data() {
    return {
      isModalVisible: false,
      aURL: '',
      aTitle: ''
    };
  },
  methods: {
    showModal(theURL, theTitle) {
      this.isModalVisible = true;
      this.aURL = theURL;
      this.aTitle = theTitle;
    },
    closeModal() {
      this.isModalVisible = false;
      this.aURL = '';
    }
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

#curriculum_content {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 10px;
  max-width: 1100px;
  text-align: left;
  font-size: 15px;
}

#chp_content {
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-size: 18px;
}

#back {
  width: 50%;
  float: left;
  text-align: left;
}

a {
  color: blue;
  text-align: center;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

h2 {
  text-align: center;
  font-size: 20px;
  padding: 0px;
}
</style>
