<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content">
    <h2>Professional Learning</h2>
    Coming soon...
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
export default {
  name: 'PdView',
  components: {
    'top-header': TopHeader
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

#content {
  text-align: center;
  margin: auto;
  width: 50%;
  padding: 10px;
  font-size: 20px;
}

h2 {
  text-align: center;
  font-size: 22px;
  padding: 0px;
}
</style>
