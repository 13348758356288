import createPersistedState from 'vuex-persistedstate';
import { createStore } from 'vuex';

export default createStore({
  state: {
    user: {
      id: '',
      name: '',
      code: '',
      email: ''
    }
  },
  mutations: {
    STORE_USER(state, aUser) {
      state.user.id = aUser.id;
      state.user.name = aUser.name;
      state.user.code = aUser.code;
      state.user.email = aUser.email;
    }
  },
  actions: {},
  plugins: [createPersistedState()],
  modules: {}
});