<template>
  <public-header></public-header>
  <div id="spacer"></div>
  <div id="content">
    <div class="div1">
      <h1 id="mainhead">
        Our Why
      </h1>
      <div id="box3">
        <p id="descript1">
          Early education is all about creating a foundation for future learning. Since there has
          been formal education, it has been accepted that the 3R's (Reading, wRiting, and
          aRithmetic) were key skills. This served us well for over 100 years, but are they enough
          to serve students growing up in a digital age? Maybe it's time for our definition of
          foundational skills to expand. From artificial intelligence to virtual reality digital,
          technology is becoming more embedded in our everyday lives. Our students need to be
          taught how to create with technology, not just use it. Armed with this digital foundation,
          kids will have the skills they need to leverage computers to tackle the problems they are
          sure to face, from daily tasks to success in school and work to changing the world.
        </p>
      </div>
      <div id="box4">
          <img
          id="gang"
          src="../assets/images/chip_self.png"
          style="margin-top: 30px;"
        />
      </div>
    </div>
    <div class="div2">
      <h1 id="mainhead">
          How It Works
      </h1>
      <div id="box5">
          <img
          id="gang"
          src="../assets/images/vidi_self.png"
          style="margin-top: 30px;"
        />
      </div>
      <div id="box6">
        <p id="descript1">
          The story is divided into 18 chapters that can be completed in about an hour each. 
          Students start by reading the chapter on the app with provided narration and animated
          text for emerging readers. Standards-aligned computational thinking and literacy 
          activities are embedded in each chapter. After the student completes all the activities, 
          the chapter is complete. Last, students work offline completing the same chapter in their 
          CompuTales workbook. The workbook provides hands-on reinforcement for the concepts 
          introduced with the story app. 
        </p>
      </div>
    </div>
    <div id="div3">
      <div id="content3">
        <h1 id="mainhead">
          Features
        </h1>
        <div id="box1">
          <ul class="features">
            <li class="features">Animated text for emerging readers</li>
            <li class="features">
              Computational and literacy activities
            </li>
            <li class="features">Picture-based login</li>
            <li class="features">Game world format</li>
          </ul>
        </div>
        <div id="box2">
          <ul class="features">
            <li class="features">Colorful pictures and animations</li>
            <li class="features">Block-based coding</li>
            <li class="features">Drawings saved in the cloud</li>
            <li class="features">Integrated offline workbook</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer">
      <div id="content4">
        <div id="brandinfo">
          <img alt="CompuTales Logo" src="../assets/images/logo3.png" /><br />
          CompuTales helps students to learn the building blocks of Computer
          Science with a fun and interactive story. Narrated for emerging
          readers, and aligned to CSTA standards for K-2.
        </div>
        <div id="aboutinfo">
          <p id="header-heading">
            <router-link :to="{ name: 'about' }" class="link-footer"
              >About</router-link
            >
          </p>
          <ul class="no-bullets">
            <li>Our Why</li>
            <li>How It Works</li>
            <li>Features</li>
          </ul>
        </div>
        <div id="educatorinfo">
          <p id="header-heading">
            <router-link :to="{ name: 'educators' }" class="link-footer"
              >Educators</router-link
            >
          </p>
          <ul class="no-bullets">
            <li>Curriculum</li>
            <li>Benefits</li>
            <li>FAQs</li>
          </ul>
        </div>
        <div id="storeinfo">
          <p id="header-heading">
            <router-link :to="{ name: 'store' }" class="link-footer"
              >Store</router-link
            >
          </p>
          <ul class="no-bullets">
            <li>Workbooks</li>
            <li>Subscribe</li>
            <li>&nbsp;</li>
          </ul>
        </div>
        <div id="connectinfo">
          <p id="header-heading">
            <router-link :to="{ name: 'contact' }" class="link-footer"
              >Connect With Us</router-link
            >
          </p>
          <ul class="no-bullets">
            <li>Contact</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PublicHeader from '@/components/Public-Header';
export default {
  name: 'AboutView',
  components: {
    'public-header': PublicHeader
  }
};
</script>

<style scoped>
@media only screen and (min-width: 901px) {
  div.div1 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  div.div1 {
    font-size: 14px;
  }
}

@media only screen and (min-width: 901px) {
  div.div2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  div.div2 {
    font-size: 14px;
  }
}

@media (min-width: 901px) {
  h1 {
    font-size: 36px;
  }
}

@media (max-width: 900px) {
  h1 {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  div#connectinfo {
    display: none;
  }
}

@media (max-width: 1300px) {
  div#storeinfo {
    display: none;
  }
}

@media (max-width: 1000px) {
  div#educatorinfo {
    display: none;
  }
}

@media (max-width: 800px) {
  div#aboutinfo {
    display: none;
  }
}

@media (max-width: 1400px) {
  img#gang {
    display: none;
  }
}

div.div1 {
  margin-top: 86px;
  text-align: center;
  display: inline-block;
  width: 60%;
}

div.div2 {
  text-align: center;
  display: inline-block;
  width: 60%;
}

#div3 {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
  margin: 0px;
  margin-bottom: 200px;
}

.footer {
  background-color: rgb(50, 50, 50);
  clear: both;
  position: relative;
  height: 200px;
  margin-top: -200px;
}

#content {
  text-align: center;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}

#content3 {
  max-width: 1150px;
}

#content4 {
  width: 70%;
  color: white;
  padding-top: 20px;
  margin: auto;
}

#brandinfo {
  font-size: 14px;
  width: 320px;
  float: left;
  text-align: left;
  line-height: 1.6;
}

#aboutinfo {
  width: 140px;
  text-align: left;
  line-height: 1.6;
  margin-left: 50px;
  display: inline-block;
}

#educatorinfo {
  width: 140px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#storeinfo {
  width: 140px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#connectinfo {
  width: 220px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#header-heading {
  font-size: 16px;
  padding: 0;
  margin-bottom: 0px;
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
  margin-left: 8px;
  font-size: 14px;
}

#mainhead {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 10px;
  margin: 10px;
}

#descript1 {
  text-align: left;
  display: flex;
  line-height: 1.8;
  margin-top: 20px;
  margin-right: 30px;
}

#checkhead {
  font-size: 32px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0px;
}

.link {
  font-weight: 500;
  color: blue;
  list-style: none;
  text-decoration: none;
  font-size: 20px;
  transition: 0.5s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}

.link-footer {
  font-weight: 500;
  color: white;
  list-style: none;
  text-decoration: none;
  font-size: 18px;
  transition: 0.5s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}

.link:hover {
  color: blue;
  border-color: #00afea;
}

.link-footer:hover {
  color: white;
  border-color: #00afea;
}

ul.features {
  float: left;
  margin-left: 0;
  padding-right: 0;
  margin-top: 0px;
  text-align: left;
}

li.features {
  position: relative;
  padding: 0 0 0 0px;
  margin: 0px;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: left;
  margin-top: 0.8em;
}

#box1 {
  font-size: 20px;
  padding: 10px;
  width: 440px;
  height: 200px;
  float: left;
  background-color: rgb(203, 252, 205);
  border-radius: 25px;
  border: 4px solid #01a50f;
  line-height: 1.5;
  margin-right: 50px;
  margin-top: 40px;
}

#box2 {
  font-size: 20px;
  padding: 10px;
  width: 440px;
  height: 200px;
  float: right;
  background-color: rgb(203, 252, 205);
  border-radius: 25px;
  border: 4px solid #01a50f;
  line-height: 1.5;
  margin-left: 50px;
  margin-top: 40px;
}

#box3 {
  font-size: 20px;
  max-width: 930px;
  float: left;
  line-height: 1.5;
}

#box4 {
  width: 200px;
  float: right;
}

#box5 {
  width: 200px;
  float: left;
}

#box6 {
  font-size: 20px;
  padding-left: 20px;
  max-width: 900px;
  float: right;
  line-height: 1.5;
}
</style>
