<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content" v-if="dataReady">
    <strong>Edit Class: {{ class_name }}</strong>
    <div v-if="error" class="error">{{ error }}</div>
    <form @submit.prevent="pressed">
      <div>
        <input v-model="class_name" placeholder="Class Name" />
      </div>

      <span v-if="paid == 'yes'" class="container2">Language:</span>
      <label v-if="paid == 'yes'" class="container3"
        >English
        <input
          type="radio"
          name="langradio"
          value="English"
          v-model="language"
          :checked="language == 'English'"
        />
        <span class="checkmark"></span>
      </label>
      <label v-if="paid == 'yes'" class="container3"
        >Spanish
        <input
          type="radio"
          name="langradio"
          value="Spanish"
          v-model="language"
          :checked="language == 'Spanish'"
        />
        <span class="checkmark"></span>
      </label>

      <div id="access_heading">
        <strong>Available Student Chapters</strong>
      </div>
      <div id="access_content">
        <div id="left_list">
          <ul>
            <li v-for="chp in chapters9" :key="chp.chapter">
              <label class="container"
                >{{ chp.chapter }}
                <input
                  type="checkbox"
                  label="chp.chapter"
                  v-model="chp.active"
                />
                <span class="checkmark"></span>
              </label>
            </li>
          </ul>
        </div>
        <div id="right_list">
          <ul>
            <li v-for="chp in chapters18" :key="chp.chapter">
              <label class="container"
                >{{ chp.chapter }}
                <input
                  type="checkbox"
                  label="chp.chapter"
                  v-model="chp.active"
                />
                <span class="checkmark"></span>
              </label>
            </li>
          </ul>
        </div>
        <div>
          <n-button @click="handleClick" ghost type="primary" color="blue"
            >Update Class</n-button
          >
        </div>
      </div>
    </form>
  </div>
  <div id="content" v-else>
    Data loading, please wait...
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';
import { NButton } from 'naive-ui';

export default {
  name: 'EditClass',
  components: {
    'top-header': TopHeader,
    'n-button': NButton
  },
  methods: {
    async handleClick() {
      try {
        if (this.class_name.length == 0)
          throw 'Please enter a name for the class';
        if (this.class_name.length > 19)
          throw 'Class name must be less than 20 characters';
        else {
          var db = firebase.firestore();
          await db
            .collection('classes')
            .doc(this.classID)
            .set(
              {
                name: this.class_name,
                chapters9: this.chapters9,
                chapters18: this.chapters18,
                language: this.language
              },
              { merge: true }
            );
          this.$router.replace({ name: 'classes' });
        }
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    }
  },
  data() {
    return {
      error: '',
      dataReady: false,
      class_name: this.$route.query.class,
      classID: this.$route.query.classID,
      chapters9: [],
      chapters18: [],
      language: '',
      picked: '',
      paid: this.$store.state.user.paid
    };
  },
  async mounted() {
    var db = firebase.firestore();
    try {
      let docRef = db.collection('classes').doc(this.classID);
      this.aClass = await docRef.get();
      this.chapters9 = this.aClass.data().chapters9;
      this.chapters18 = this.aClass.data().chapters18;
      this.language = this.aClass.data().language;
      this.dataReady = true;
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

.error {
  color: red;
  font-size: 18px;
}

input {
  width: 300px;
  padding: 10px;
  margin: 10px;
  font-size: 20px;
  text-align: left;
}

button {
  width: 150px;
  height: 35px;
  font-size: 18px;
  padding: 15px;
  margin: 20px;
}

#content {
  text-align: center;
  margin: auto;
  width: 600px;
  padding: 10px;
  font-size: 20px;
}

#access_heading {
  text-align: center;
  margin: auto;
  padding: 20px;
  font-size: 20px;
  width: 320px;
}

#access_content {
  text-align: center;
  margin: auto;
  padding: 5px;
  width: 360px;
  font-size: 20px;
}

#left_list {
  float: left;
  width: 56%;
  text-align: left;
}

#right_list {
  text-align: left;
  float: left;
}

ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Customize the label (the container) */
.container3 {
  display: inline-flex;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container2 {
  display: inline-flex;
  position: relative;
  padding-left: 35px;
  padding-right: 10px;
  margin-bottom: 12px;
  margin-top: 18px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container3 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container3:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container3 input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Show the indicator (dot/circle) when checked */
.container3 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container3 .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
</style>
