<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div v-if="error" class="error">{{ error }}</div>
  <div id="content" v-if="dataReady">
    <ModalDrawing
      :img_url="aURL"
      :theTitle="aTitle"
      v-show="isModalVisible"
      @close="closeModal"
    />
    <div id="title2">
      <span id="back">
        <router-link
          :to="{
            name: 'progress',
            query: { classID: classID, class: className }
          }"
          ><img alt="Edit" src="../../assets/images/arrow_black_left.png" />Back
          to {{ className }} Progress</router-link
        ></span
      >
      <span id="studname">{{ aStud.data().name }} </span>
    </div>
    <br />
    <div id="curriculum_content">
      <div id="left_list">
        <n-collapse accordion>
          <n-collapse-item title="Chapter 1" name="1">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch1[1] }}</td>
                <td id="chp_data_cell">{{ ch1[2] }}</td>
                <td id="chp_data_cell">{{ ch1[3] }}</td>
                <td v-if="ch1[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch1[4], 'Draw something you like to do.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch1[5] }}</td>
                <td id="chp_data_cell">{{ ch1[6] }}</td>
                <td id="chp_data_cell">{{ ch1[7] }}</td>
                <td id="chp_data_cell">{{ ch1[8] }}</td>
              </tr>
            </table>

            <template v-if="ch1[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 2" name="2">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch2[1] }}</td>
                <td id="chp_data_cell">{{ ch2[2] }}</td>
                <td id="chp_data_cell">{{ ch2[3] }}</td>
                <td v-if="ch2[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch2[4], 'Draw you with a friend.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch2[5] }}</td>
                <td id="chp_data_cell">{{ ch2[6] }}</td>
                <td id="chp_data_cell">{{ ch2[7] }}</td>
                <td id="chp_data_cell">{{ ch2[8] }}</td>
              </tr>
            </table>

            <template v-if="ch2[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 3" name="3">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch3[1] }}</td>
                <td id="chp_data_cell">{{ ch3[2] }}</td>
                <td id="chp_data_cell">{{ ch3[3] }}</td>
                <td v-if="ch3[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="
                      showModal(
                        ch3[4],
                        'Draw something you might find in a park.'
                      )
                    "
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch3[5] }}</td>
                <td id="chp_data_cell">{{ ch3[6] }}</td>
                <td id="chp_data_cell">{{ ch3[7] }}</td>
                <td id="chp_data_cell">{{ ch3[8] }}</td>
              </tr>
            </table>

            <template v-if="ch3[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 4" name="4">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch4[1] }}</td>
                <td id="chp_data_cell">{{ ch4[2] }}</td>
                <td id="chp_data_cell">{{ ch4[3] }}</td>
                <td v-if="ch4[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch4[4], 'Draw your home.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch4[5] }}</td>
                <td id="chp_data_cell">{{ ch4[6] }}</td>
                <td id="chp_data_cell">{{ ch4[7] }}</td>
                <td id="chp_data_cell">{{ ch4[8] }}</td>
              </tr>
            </table>

            <template v-if="ch4[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 5" name="5">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch5[1] }}</td>
                <td id="chp_data_cell">{{ ch5[2] }}</td>
                <td id="chp_data_cell">{{ ch5[3] }}</td>
                <td v-if="ch5[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch5[4], 'Draw a pattern of shapes.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch5[5] }}</td>
                <td id="chp_data_cell">{{ ch5[6] }}</td>
                <td id="chp_data_cell">{{ ch5[7] }}</td>
                <td id="chp_data_cell">{{ ch5[8] }}</td>
              </tr>
            </table>

            <template v-if="ch5[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 6" name="6">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch6[1] }}</td>
                <td id="chp_data_cell">{{ ch6[2] }}</td>
                <td id="chp_data_cell">{{ ch6[3] }}</td>
                <td v-if="ch6[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch6[4], 'Draw a map to a secret place.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch6[5] }}</td>
                <td id="chp_data_cell">{{ ch6[6] }}</td>
                <td id="chp_data_cell">{{ ch6[7] }}</td>
                <td id="chp_data_cell">{{ ch6[8] }}</td>
              </tr>
            </table>

            <template v-if="ch6[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 7" name="7">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch7[1] }}</td>
                <td id="chp_data_cell">{{ ch7[2] }}</td>
                <td id="chp_data_cell">{{ ch7[3] }}</td>
                <td v-if="ch7[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch7[4], 'Draw a bridge.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch7[5] }}</td>
                <td id="chp_data_cell">{{ ch7[6] }}</td>
                <td id="chp_data_cell">{{ ch7[7] }}</td>
                <td id="chp_data_cell">{{ ch7[8] }}</td>
              </tr>
            </table>

            <template v-if="ch7[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 8" name="8">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch8[1] }}</td>
                <td id="chp_data_cell">{{ ch8[2] }}</td>
                <td id="chp_data_cell">{{ ch8[3] }}</td>
                <td v-if="ch8[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch8[4], 'Draw a boat.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch8[5] }}</td>
                <td id="chp_data_cell">{{ ch8[6] }}</td>
                <td id="chp_data_cell">{{ ch8[7] }}</td>
                <td id="chp_data_cell">{{ ch8[8] }}</td>
              </tr>
            </table>

            <template v-if="ch8[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 9" name="9">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch9[1] }}</td>
                <td id="chp_data_cell">{{ ch9[2] }}</td>
                <td id="chp_data_cell">{{ ch9[3] }}</td>
                <td v-if="ch9[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="
                      showModal(
                        ch9[4],
                        'Draw you with your family taking a trip.'
                      )
                    "
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch9[5] }}</td>
                <td id="chp_data_cell">{{ ch9[6] }}</td>
                <td id="chp_data_cell">{{ ch9[7] }}</td>
                <td id="chp_data_cell">{{ ch9[8] }}</td>
              </tr>
            </table>

            <template v-if="ch9[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
        </n-collapse>
      </div>
      <div id="right_list">
        <n-collapse accordion>
          <n-collapse-item title="Chapter 10" name="10">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch10[1] }}</td>
                <td id="chp_data_cell">{{ ch10[2] }}</td>
                <td id="chp_data_cell">{{ ch10[3] }}</td>
                <td v-if="ch10[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch10[4], 'Draw someone doing a job.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch10[5] }}</td>
                <td id="chp_data_cell">{{ ch10[6] }}</td>
                <td id="chp_data_cell">{{ ch10[7] }}</td>
                <td id="chp_data_cell">{{ ch10[8] }}</td>
              </tr>
            </table>

            <template v-if="ch10[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 11" name="11">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch11[1] }}</td>
                <td id="chp_data_cell">{{ ch11[2] }}</td>
                <td id="chp_data_cell">{{ ch11[3] }}</td>
                <td v-if="ch11[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="
                      showModal(
                        ch11[4],
                        'Draw your favorite character dancing.'
                      )
                    "
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch11[5] }}</td>
                <td id="chp_data_cell">{{ ch11[6] }}</td>
                <td id="chp_data_cell">{{ ch11[7] }}</td>
                <td id="chp_data_cell">{{ ch11[8] }}</td>
              </tr>
            </table>

            <template v-if="ch11[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 12" name="12">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch12[1] }}</td>
                <td id="chp_data_cell">{{ ch12[2] }}</td>
                <td id="chp_data_cell">{{ ch12[3] }}</td>
                <td v-if="ch12[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch12[4], 'Draw three flowers.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch12[5] }}</td>
                <td id="chp_data_cell">{{ ch12[6] }}</td>
                <td id="chp_data_cell">{{ ch12[7] }}</td>
                <td id="chp_data_cell">{{ ch12[8] }}</td>
              </tr>
            </table>

            <template v-if="ch12[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 13" name="13">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch13[1] }}</td>
                <td id="chp_data_cell">{{ ch13[2] }}</td>
                <td id="chp_data_cell">{{ ch13[3] }}</td>
                <td v-if="ch13[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch13[4], 'Draw your own magic key.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch13[5] }}</td>
                <td id="chp_data_cell">{{ ch13[6] }}</td>
                <td id="chp_data_cell">{{ ch13[7] }}</td>
                <td id="chp_data_cell">{{ ch13[8] }}</td>
              </tr>
            </table>

            <template v-if="ch13[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 14" name="14">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch14[1] }}</td>
                <td id="chp_data_cell">{{ ch14[2] }}</td>
                <td id="chp_data_cell">{{ ch14[3] }}</td>
                <td v-if="ch14[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch14[4], 'Draw Squeak the mouse.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch14[5] }}</td>
                <td id="chp_data_cell">{{ ch14[6] }}</td>
                <td id="chp_data_cell">{{ ch14[7] }}</td>
                <td id="chp_data_cell">{{ ch14[8] }}</td>
              </tr>
            </table>

            <template v-if="ch14[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 15" name="15">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch15[1] }}</td>
                <td id="chp_data_cell">{{ ch15[2] }}</td>
                <td id="chp_data_cell">{{ ch15[3] }}</td>
                <td v-if="ch15[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch15[4], 'Draw someplace you feel safe.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch15[5] }}</td>
                <td id="chp_data_cell">{{ ch15[6] }}</td>
                <td id="chp_data_cell">{{ ch15[7] }}</td>
                <td id="chp_data_cell">{{ ch15[8] }}</td>
              </tr>
            </table>

            <template v-if="ch15[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 16" name="16">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch16[1] }}</td>
                <td id="chp_data_cell">{{ ch16[2] }}</td>
                <td id="chp_data_cell">{{ ch16[3] }}</td>
                <td v-if="ch16[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="
                      showModal(ch16[4], 'Draw an action you like to do.')
                    "
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch16[5] }}</td>
                <td id="chp_data_cell">{{ ch16[6] }}</td>
                <td id="chp_data_cell">{{ ch16[7] }}</td>
                <td id="chp_data_cell">{{ ch16[8] }}</td>
              </tr>
            </table>

            <template v-if="ch16[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 17" name="17">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch17[1] }}</td>
                <td id="chp_data_cell">{{ ch17[2] }}</td>
                <td id="chp_data_cell">{{ ch17[3] }}</td>
                <td v-if="ch17[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch17[4], 'Draw a rainbow.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch17[5] }}</td>
                <td id="chp_data_cell">{{ ch17[6] }}</td>
                <td id="chp_data_cell">{{ ch17[7] }}</td>
                <td id="chp_data_cell">{{ ch17[8] }}</td>
              </tr>
            </table>

            <template v-if="ch17[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
          <n-collapse-item title="Chapter 18" name="18">
            <table id="chp_data">
              <tr>
                <th id="chp_head_cell">&nbsp;</th>
                <th id="chp_data_cell">1</th>
                <th id="chp_data_cell">2</th>
                <th id="chp_data_cell">3</th>
                <th id="chp_data_cell">4</th>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Read</strong></td>
                <td id="chp_data_cell">{{ ch18[1] }}</td>
                <td id="chp_data_cell">{{ ch18[2] }}</td>
                <td id="chp_data_cell">{{ ch18[3] }}</td>
                <td v-if="ch18[4] != 'No'" id="chp_data_cell">
                  <a
                    id="modal_link"
                    href="#"
                    @click="showModal(ch18[4], 'Draw your family.')"
                  >
                    Yes
                  </a>
                </td>
                <td v-else id="chp_data_cell">
                  No
                </td>
              </tr>
              <tr>
                <td id="chp_head_cell"><strong>Compute</strong></td>
                <td id="chp_data_cell">{{ ch18[5] }}</td>
                <td id="chp_data_cell">{{ ch18[6] }}</td>
                <td id="chp_data_cell">{{ ch18[7] }}</td>
                <td id="chp_data_cell">{{ ch18[8] }}</td>
              </tr>
            </table>

            <template v-if="ch18[0]" #header-extra>
              <img src="../../assets/images/green_check.png" />
            </template>
            <template v-else #header-extra>
              <img src="../../assets/images/red_x.png" />
            </template>
          </n-collapse-item>
        </n-collapse>
      </div>
    </div>
  </div>

  <div id="content" v-else>
    Data loading, please wait...
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';
import ModalDrawing from '@/components/Modal-Drawing.vue';
import { NCollapse } from 'naive-ui';
import { NCollapseItem } from 'naive-ui';

export default {
  name: 'StudentDetail',
  components: {
    'top-header': TopHeader,
    ModalDrawing,
    'n-collapse': NCollapse,
    'n-collapse-item': NCollapseItem
  },
  data() {
    return {
      error: '',
      dataReady: false,
      isModalVisible: false,
      aURL: '',
      aTitle: '',
      aStud: null,
      studentID: this.$route.query.studentID,
      className: this.$route.query.class,
      classID: this.$route.query.classID,
      ch1: [],
      ch2: [],
      ch3: [],
      ch4: [],
      ch5: [],
      ch6: [],
      ch7: [],
      ch8: [],
      ch9: [],
      ch10: [],
      ch11: [],
      ch12: [],
      ch13: [],
      ch14: [],
      ch15: [],
      ch16: [],
      ch17: [],
      ch18: []
    };
  },
  methods: {
    showModal(theURL, theTitle) {
      this.isModalVisible = true;
      this.aURL = theURL;
      this.aTitle = theTitle;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  },
  async mounted() {
    var db = firebase.firestore();
    try {
      let docRef = db.collection('users').doc(this.studentID);
      this.aStud = await docRef.get();
      if (this.aStud.data().read1_4 && this.aStud.data().compute1_4)
        this.ch1[0] = 'yes';
      if (this.aStud.data().read1_1) this.ch1[1] = 'Yes';
      else this.ch1[1] = 'No';
      if (this.aStud.data().read1_2) this.ch1[2] = 'Yes';
      else this.ch1[2] = 'No';
      if (this.aStud.data().read1_3) this.ch1[3] = 'Yes';
      else this.ch1[3] = 'No';
      if (this.aStud.data().read1_4) this.ch1[4] = this.aStud.data().read1_4;
      else this.ch1[4] = 'No';
      if (this.aStud.data().compute1_1) this.ch1[5] = 'Yes';
      else this.ch1[5] = 'No';
      if (this.aStud.data().compute1_2) this.ch1[6] = 'Yes';
      else this.ch1[6] = 'No';
      if (this.aStud.data().compute1_3) this.ch1[7] = 'Yes';
      else this.ch1[7] = 'No';
      if (this.aStud.data().compute1_4) this.ch1[8] = 'Yes';
      else this.ch1[8] = 'No';

      if (this.aStud.data().read2_4 && this.aStud.data().compute2_4)
        this.ch2[0] = 'yes';
      if (this.aStud.data().read2_1) this.ch2[1] = 'Yes';
      else this.ch2[1] = 'No';
      if (this.aStud.data().read2_2) this.ch2[2] = 'Yes';
      else this.ch2[2] = 'No';
      if (this.aStud.data().read2_3) this.ch2[3] = 'Yes';
      else this.ch2[3] = 'No';
      if (this.aStud.data().read2_4) this.ch2[4] = this.aStud.data().read2_4;
      else this.ch2[4] = 'No';
      if (this.aStud.data().compute2_1) this.ch2[5] = 'Yes';
      else this.ch2[5] = 'No';
      if (this.aStud.data().compute2_2) this.ch2[6] = 'Yes';
      else this.ch2[6] = 'No';
      if (this.aStud.data().compute2_3) this.ch2[7] = 'Yes';
      else this.ch2[7] = 'No';
      if (this.aStud.data().compute2_4) this.ch2[8] = 'Yes';
      else this.ch2[8] = 'No';

      if (this.aStud.data().read3_4 && this.aStud.data().compute3_4)
        this.ch3[0] = 'yes';
      if (this.aStud.data().read3_1) this.ch3[1] = 'Yes';
      else this.ch3[1] = 'No';
      if (this.aStud.data().read3_2) this.ch3[2] = 'Yes';
      else this.ch3[2] = 'No';
      if (this.aStud.data().read3_3) this.ch3[3] = 'Yes';
      else this.ch3[3] = 'No';
      if (this.aStud.data().read3_4) this.ch3[4] = this.aStud.data().read3_4;
      else this.ch3[4] = 'No';
      if (this.aStud.data().compute3_1) this.ch3[5] = 'Yes';
      else this.ch3[5] = 'No';
      if (this.aStud.data().compute3_2) this.ch3[6] = 'Yes';
      else this.ch3[6] = 'No';
      if (this.aStud.data().compute3_3) this.ch3[7] = 'Yes';
      else this.ch3[7] = 'No';
      if (this.aStud.data().compute3_4) this.ch3[8] = 'Yes';
      else this.ch3[8] = 'No';

      if (this.aStud.data().read4_4 && this.aStud.data().compute4_4)
        this.ch4[0] = 'yes';
      if (this.aStud.data().read4_1) this.ch4[1] = 'Yes';
      else this.ch4[1] = 'No';
      if (this.aStud.data().read4_2) this.ch4[2] = 'Yes';
      else this.ch4[2] = 'No';
      if (this.aStud.data().read4_3) this.ch4[3] = 'Yes';
      else this.ch4[3] = 'No';
      if (this.aStud.data().read4_4) this.ch4[4] = this.aStud.data().read4_4;
      else this.ch4[4] = 'No';
      if (this.aStud.data().compute4_1) this.ch4[5] = 'Yes';
      else this.ch4[5] = 'No';
      if (this.aStud.data().compute4_2) this.ch4[6] = 'Yes';
      else this.ch4[6] = 'No';
      if (this.aStud.data().compute4_3) this.ch4[7] = 'Yes';
      else this.ch4[7] = 'No';
      if (this.aStud.data().compute4_4) this.ch4[8] = 'Yes';
      else this.ch4[8] = 'No';

      if (this.aStud.data().read5_4 && this.aStud.data().compute5_4)
        this.ch5[0] = 'yes';
      if (this.aStud.data().read5_1) this.ch5[1] = 'Yes';
      else this.ch5[1] = 'No';
      if (this.aStud.data().read5_2) this.ch5[2] = 'Yes';
      else this.ch5[2] = 'No';
      if (this.aStud.data().read5_3) this.ch5[3] = 'Yes';
      else this.ch5[3] = 'No';
      if (this.aStud.data().read5_4) this.ch5[4] = this.aStud.data().read5_4;
      else this.ch5[4] = 'No';
      if (this.aStud.data().compute5_1) this.ch5[5] = 'Yes';
      else this.ch5[5] = 'No';
      if (this.aStud.data().compute5_2) this.ch5[6] = 'Yes';
      else this.ch5[6] = 'No';
      if (this.aStud.data().compute5_3) this.ch5[7] = 'Yes';
      else this.ch5[7] = 'No';
      if (this.aStud.data().compute5_4) this.ch5[8] = 'Yes';
      else this.ch5[8] = 'No';

      if (this.aStud.data().read6_4 && this.aStud.data().compute6_4)
        this.ch6[0] = 'yes';
      if (this.aStud.data().read6_1) this.ch6[1] = 'Yes';
      else this.ch6[1] = 'No';
      if (this.aStud.data().read6_2) this.ch6[2] = 'Yes';
      else this.ch6[2] = 'No';
      if (this.aStud.data().read6_3) this.ch6[3] = 'Yes';
      else this.ch6[3] = 'No';
      if (this.aStud.data().read6_4) this.ch6[4] = this.aStud.data().read6_4;
      else this.ch6[4] = 'No';
      if (this.aStud.data().compute6_1) this.ch6[5] = 'Yes';
      else this.ch6[5] = 'No';
      if (this.aStud.data().compute6_2) this.ch6[6] = 'Yes';
      else this.ch6[6] = 'No';
      if (this.aStud.data().compute6_3) this.ch6[7] = 'Yes';
      else this.ch6[7] = 'No';
      if (this.aStud.data().compute6_4) this.ch6[8] = 'Yes';
      else this.ch6[8] = 'No';

      if (this.aStud.data().read7_4 && this.aStud.data().compute7_4)
        this.ch7[0] = 'yes';
      if (this.aStud.data().read7_1) this.ch7[1] = 'Yes';
      else this.ch7[1] = 'No';
      if (this.aStud.data().read7_2) this.ch7[2] = 'Yes';
      else this.ch7[2] = 'No';
      if (this.aStud.data().read7_3) this.ch7[3] = 'Yes';
      else this.ch7[3] = 'No';
      if (this.aStud.data().read7_4) this.ch7[4] = this.aStud.data().read7_4;
      else this.ch7[4] = 'No';
      if (this.aStud.data().compute7_1) this.ch7[5] = 'Yes';
      else this.ch7[5] = 'No';
      if (this.aStud.data().compute7_2) this.ch7[6] = 'Yes';
      else this.ch7[6] = 'No';
      if (this.aStud.data().compute7_3) this.ch7[7] = 'Yes';
      else this.ch7[7] = 'No';
      if (this.aStud.data().compute7_4) this.ch7[8] = 'Yes';
      else this.ch7[8] = 'No';

      if (this.aStud.data().read8_4 && this.aStud.data().compute8_4)
        this.ch8[0] = 'yes';
      if (this.aStud.data().read8_1) this.ch8[1] = 'Yes';
      else this.ch8[1] = 'No';
      if (this.aStud.data().read8_2) this.ch8[2] = 'Yes';
      else this.ch8[2] = 'No';
      if (this.aStud.data().read8_3) this.ch8[3] = 'Yes';
      else this.ch8[3] = 'No';
      if (this.aStud.data().read8_4) this.ch8[4] = this.aStud.data().read8_4;
      else this.ch8[4] = 'No';
      if (this.aStud.data().compute8_1) this.ch8[5] = 'Yes';
      else this.ch8[5] = 'No';
      if (this.aStud.data().compute8_2) this.ch8[6] = 'Yes';
      else this.ch8[6] = 'No';
      if (this.aStud.data().compute8_3) this.ch8[7] = 'Yes';
      else this.ch8[7] = 'No';
      if (this.aStud.data().compute8_4) this.ch8[8] = 'Yes';
      else this.ch8[8] = 'No';

      if (this.aStud.data().read9_4 && this.aStud.data().compute9_4)
        this.ch9[0] = 'yes';
      if (this.aStud.data().read9_1) this.ch9[1] = 'Yes';
      else this.ch9[1] = 'No';
      if (this.aStud.data().read9_2) this.ch9[2] = 'Yes';
      else this.ch9[2] = 'No';
      if (this.aStud.data().read9_3) this.ch9[3] = 'Yes';
      else this.ch9[3] = 'No';
      if (this.aStud.data().read9_4) this.ch9[4] = this.aStud.data().read9_4;
      else this.ch9[4] = 'No';
      if (this.aStud.data().compute9_1) this.ch9[5] = 'Yes';
      else this.ch9[5] = 'No';
      if (this.aStud.data().compute9_2) this.ch9[6] = 'Yes';
      else this.ch9[6] = 'No';
      if (this.aStud.data().compute9_3) this.ch9[7] = 'Yes';
      else this.ch9[7] = 'No';
      if (this.aStud.data().compute9_4) this.ch9[8] = 'Yes';
      else this.ch9[8] = 'No';

      if (this.aStud.data().read10_4 && this.aStud.data().compute10_4)
        this.ch10[0] = 'yes';
      if (this.aStud.data().read10_1) this.ch10[1] = 'Yes';
      else this.ch10[1] = 'No';
      if (this.aStud.data().read10_2) this.ch10[2] = 'Yes';
      else this.ch10[2] = 'No';
      if (this.aStud.data().read10_3) this.ch10[3] = 'Yes';
      else this.ch10[3] = 'No';
      if (this.aStud.data().read10_4) this.ch10[4] = this.aStud.data().read10_4;
      else this.ch10[4] = 'No';
      if (this.aStud.data().compute10_1) this.ch10[5] = 'Yes';
      else this.ch10[5] = 'No';
      if (this.aStud.data().compute10_2) this.ch10[6] = 'Yes';
      else this.ch10[6] = 'No';
      if (this.aStud.data().compute10_3) this.ch10[7] = 'Yes';
      else this.ch10[7] = 'No';
      if (this.aStud.data().compute10_4) this.ch10[8] = 'Yes';
      else this.ch10[8] = 'No';

      if (this.aStud.data().read11_4 && this.aStud.data().compute11_4)
        this.ch11[0] = 'yes';
      if (this.aStud.data().read11_1) this.ch11[1] = 'Yes';
      else this.ch11[1] = 'No';
      if (this.aStud.data().read11_2) this.ch11[2] = 'Yes';
      else this.ch11[2] = 'No';
      if (this.aStud.data().read11_3) this.ch11[3] = 'Yes';
      else this.ch11[3] = 'No';
      if (this.aStud.data().read11_4) this.ch11[4] = this.aStud.data().read11_4;
      else this.ch11[4] = 'No';
      if (this.aStud.data().compute11_1) this.ch11[5] = 'Yes';
      else this.ch11[5] = 'No';
      if (this.aStud.data().compute11_2) this.ch11[6] = 'Yes';
      else this.ch11[6] = 'No';
      if (this.aStud.data().compute11_3) this.ch11[7] = 'Yes';
      else this.ch11[7] = 'No';
      if (this.aStud.data().compute11_4) this.ch11[8] = 'Yes';
      else this.ch11[8] = 'No';

      if (this.aStud.data().read12_4 && this.aStud.data().compute12_4)
        this.ch12[0] = 'yes';
      if (this.aStud.data().read12_1) this.ch12[1] = 'Yes';
      else this.ch12[1] = 'No';
      if (this.aStud.data().read12_2) this.ch12[2] = 'Yes';
      else this.ch12[2] = 'No';
      if (this.aStud.data().read12_3) this.ch12[3] = 'Yes';
      else this.ch12[3] = 'No';
      if (this.aStud.data().read12_4) this.ch12[4] = this.aStud.data().read12_4;
      else this.ch12[4] = 'No';
      if (this.aStud.data().compute12_1) this.ch12[5] = 'Yes';
      else this.ch12[5] = 'No';
      if (this.aStud.data().compute12_2) this.ch12[6] = 'Yes';
      else this.ch12[6] = 'No';
      if (this.aStud.data().compute12_3) this.ch12[7] = 'Yes';
      else this.ch12[7] = 'No';
      if (this.aStud.data().compute12_4) this.ch12[8] = 'Yes';
      else this.ch12[8] = 'No';

      if (this.aStud.data().read13_4 && this.aStud.data().compute13_4)
        this.ch13[0] = 'yes';
      if (this.aStud.data().read13_1) this.ch13[1] = 'Yes';
      else this.ch13[1] = 'No';
      if (this.aStud.data().read13_2) this.ch13[2] = 'Yes';
      else this.ch13[2] = 'No';
      if (this.aStud.data().read13_3) this.ch13[3] = 'Yes';
      else this.ch13[3] = 'No';
      if (this.aStud.data().read13_4) this.ch13[4] = this.aStud.data().read13_4;
      else this.ch13[4] = 'No';
      if (this.aStud.data().compute13_1) this.ch13[5] = 'Yes';
      else this.ch13[5] = 'No';
      if (this.aStud.data().compute13_2) this.ch13[6] = 'Yes';
      else this.ch13[6] = 'No';
      if (this.aStud.data().compute13_3) this.ch13[7] = 'Yes';
      else this.ch13[7] = 'No';
      if (this.aStud.data().compute13_4) this.ch13[8] = 'Yes';
      else this.ch13[8] = 'No';

      if (this.aStud.data().read14_4 && this.aStud.data().compute14_4)
        this.ch14[0] = 'yes';
      if (this.aStud.data().read14_1) this.ch14[1] = 'Yes';
      else this.ch14[1] = 'No';
      if (this.aStud.data().read14_2) this.ch14[2] = 'Yes';
      else this.ch14[2] = 'No';
      if (this.aStud.data().read14_3) this.ch14[3] = 'Yes';
      else this.ch14[3] = 'No';
      if (this.aStud.data().read14_4) this.ch14[4] = this.aStud.data().read14_4;
      else this.ch14[4] = 'No';
      if (this.aStud.data().compute14_1) this.ch14[5] = 'Yes';
      else this.ch14[5] = 'No';
      if (this.aStud.data().compute14_2) this.ch14[6] = 'Yes';
      else this.ch14[6] = 'No';
      if (this.aStud.data().compute14_3) this.ch14[7] = 'Yes';
      else this.ch14[7] = 'No';
      if (this.aStud.data().compute14_4) this.ch14[8] = 'Yes';
      else this.ch14[8] = 'No';

      if (this.aStud.data().read15_4 && this.aStud.data().compute15_4)
        this.ch15[0] = 'yes';
      if (this.aStud.data().read15_1) this.ch15[1] = 'Yes';
      else this.ch15[1] = 'No';
      if (this.aStud.data().read15_2) this.ch15[2] = 'Yes';
      else this.ch15[2] = 'No';
      if (this.aStud.data().read15_3) this.ch15[3] = 'Yes';
      else this.ch15[3] = 'No';
      if (this.aStud.data().read15_4) this.ch15[4] = this.aStud.data().read15_4;
      else this.ch15[4] = 'No';
      if (this.aStud.data().compute15_1) this.ch15[5] = 'Yes';
      else this.ch15[5] = 'No';
      if (this.aStud.data().compute15_2) this.ch15[6] = 'Yes';
      else this.ch15[6] = 'No';
      if (this.aStud.data().compute15_3) this.ch15[7] = 'Yes';
      else this.ch15[7] = 'No';
      if (this.aStud.data().compute15_4) this.ch15[8] = 'Yes';
      else this.ch15[8] = 'No';

      if (this.aStud.data().read16_4 && this.aStud.data().compute16_4)
        this.ch16[0] = 'yes';
      if (this.aStud.data().read16_1) this.ch16[1] = 'Yes';
      else this.ch16[1] = 'No';
      if (this.aStud.data().read16_2) this.ch16[2] = 'Yes';
      else this.ch16[2] = 'No';
      if (this.aStud.data().read16_3) this.ch16[3] = 'Yes';
      else this.ch16[3] = 'No';
      if (this.aStud.data().read16_4) this.ch16[4] = this.aStud.data().read16_4;
      else this.ch16[4] = 'No';
      if (this.aStud.data().compute16_1) this.ch16[5] = 'Yes';
      else this.ch16[5] = 'No';
      if (this.aStud.data().compute16_2) this.ch16[6] = 'Yes';
      else this.ch16[6] = 'No';
      if (this.aStud.data().compute16_3) this.ch16[7] = 'Yes';
      else this.ch16[7] = 'No';
      if (this.aStud.data().compute16_4) this.ch16[8] = 'Yes';
      else this.ch16[8] = 'No';

      if (this.aStud.data().read17_4 && this.aStud.data().compute17_4)
        this.ch17[0] = 'yes';
      if (this.aStud.data().read17_1) this.ch17[1] = 'Yes';
      else this.ch17[1] = 'No';
      if (this.aStud.data().read17_2) this.ch17[2] = 'Yes';
      else this.ch17[2] = 'No';
      if (this.aStud.data().read17_3) this.ch17[3] = 'Yes';
      else this.ch17[3] = 'No';
      if (this.aStud.data().read17_4) this.ch17[4] = this.aStud.data().read17_4;
      else this.ch17[4] = 'No';
      if (this.aStud.data().compute17_1) this.ch17[5] = 'Yes';
      else this.ch17[5] = 'No';
      if (this.aStud.data().compute17_2) this.ch17[6] = 'Yes';
      else this.ch17[6] = 'No';
      if (this.aStud.data().compute17_3) this.ch17[7] = 'Yes';
      else this.ch17[7] = 'No';
      if (this.aStud.data().compute17_4) this.ch17[8] = 'Yes';
      else this.ch17[8] = 'No';

      if (this.aStud.data().read18_4 && this.aStud.data().compute18_4)
        this.ch18[0] = 'yes';
      if (this.aStud.data().read18_1) this.ch18[1] = 'Yes';
      else this.ch18[1] = 'No';
      if (this.aStud.data().read18_2) this.ch18[2] = 'Yes';
      else this.ch18[2] = 'No';
      if (this.aStud.data().read18_3) this.ch18[3] = 'Yes';
      else this.ch18[3] = 'No';
      if (this.aStud.data().read18_4) this.ch18[4] = this.aStud.data().read18_4;
      else this.ch18[4] = 'No';
      if (this.aStud.data().compute18_1) this.ch18[5] = 'Yes';
      else this.ch18[5] = 'No';
      if (this.aStud.data().compute18_2) this.ch18[6] = 'Yes';
      else this.ch18[6] = 'No';
      if (this.aStud.data().compute18_3) this.ch18[7] = 'Yes';
      else this.ch18[7] = 'No';
      if (this.aStud.data().compute18_4) this.ch18[8] = 'Yes';
      else this.ch18[8] = 'No';
      this.dataReady = true;
    } catch (err) {
      console.log(err);
      this.error = err;
    }
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

#content {
  margin: auto;
  padding: 10px;
  text-align: center;
  max-width: 850px;
}

#title {
  font-size: 20px;
  font-weight: bold;
  margin: auto;
  padding: 5px;
}

#title2 {
  font-size: 20px;
  margin: auto;
  padding: 5px;
}

#chp_data {
  border: 1px solid;
  border-collapse: collapse;
  width: 270px;
}

#chp_head_cell {
  border: 1px solid;
  border-collapse: collapse;
  text-align: center;
}

#chp_data_cell {
  border: 1px solid;
  border-collapse: collapse;
  text-align: center;
  width: 40px;
}

#back {
  width: 50%;
  float: left;
  text-align: left;
}

#studname {
  float: left;
}

.error {
  color: red;
  font-size: 18px;
}

a {
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

#modal_link {
  color: blue;
  padding: 5px 10px;
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
}

#curriculum_content {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding-top: 30px;
  padding-bottom: 0px;
  max-width: 600px;
  text-align: left;
  font-size: 15px;
}

#left_list {
  float: left;
  width: 270px;
  text-align: left;
  padding-right: 50px;
  padding-bottom: 30px;
}

#right_list {
  text-align: left;
  width: 270px;
  float: left;
}
</style>
