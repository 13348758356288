<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <ModalActivity
    :img_url="aURL"
    :theTitle="aTitle"
    v-show="isModalVisible"
    @close="closeModal"
  />
  <div id="curriculum_content">
    <span id="back">
      <router-link to="/curriculum"
        ><img
          alt="Edit"
          src="../../../assets/images/arrow_black_left.png"
        />Back to Chapters</router-link
      ></span
    >
  </div>
  <div id="curriculum_content">
    <div id="chp_content">
      <h2>Teacher Notes - Chapter 4</h2>
      <strong>CS Standard:</strong> Collect and present the same data in various
      visual formats. (CSTA 1A-DA-06)
      <p><strong>Lesson Flow</strong></p>
      <p>Warm-up Video: Coming Soon</p>
      <p>
        Warm Up: Ask students to identify what is different about each candy in
        a bag of M&M's. (5 minutes)
      </p>
      <p>
        Online Work: Students read chapter four and complete the chapter reading
        and computing activities to earn three stars. (25 minutes)
      </p>
      <p>
        Offline Work: Students complete
        <a href="wrkbk/ch4.pdf" target="_blank">Chapter Four</a> in their
        CompuTales workbook. (20 minutes)
      </p>
      <p>
        Closing: Ask students how they could orginize the different colors of
        M&M's. (5 minutes)
      </p>
      <p>
        Literacy Discussion: Why did Squeak take Martin to a park? (5 minutes)
      </p>
      <p><strong>Resources</strong></p>
      <p>
        Story Summary: Squeak and Martin visit MotherBoard and ask her how
        Martin can go home. MotherBoard says the Portal can send him home, but
        the key is lost. She says the Champions of Computer Land can help them
        find the key. Martin and Squeak set off to find the Champions.
      </p>
      <p>
        Sample Computing Activities:
        <a
          id="modal_link"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp4_2',
              'Presenting Data'
            )
          "
          >One
        </a>
      </p>
      <p>
        Sample Reading Activities:
      </p>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import ModalActivity from '@/components/Modal-Activity.vue';
export default {
  name: 'NotesFour',
  components: {
    'top-header': TopHeader,
    ModalActivity
  },
  data() {
    return {
      isModalVisible: false,
      aURL: '',
      aTitle: ''
    };
  },
  methods: {
    showModal(theURL, theTitle) {
      this.isModalVisible = true;
      this.aURL = theURL;
      this.aTitle = theTitle;
    },
    closeModal() {
      this.isModalVisible = false;
      this.aURL = '';
    }
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

#curriculum_content {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 1100px;
  text-align: left;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 10px;
}

#chp_content {
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-size: 18px;
}

#back {
  width: 50%;
  float: left;
  text-align: left;
}

a {
  color: blue;
  text-align: center;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

h2 {
  text-align: center;
  font-size: 20px;
  padding: 0px;
}
</style>
