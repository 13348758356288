<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle">
          &nbsp;&nbsp;{{ theTitle }}
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            X
          </button>
        </header>

        <section class="modal-body" id="modalDescription">
          <div name="body"><img :src="img_url" /></div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalDrawing',
  props: ['img_url', 'theTitle'],
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.modal-header {
  padding: 0px;
  display: flex;
  z-index: 100;
}

.modal-header {
  position: relative;
  color: #000000;
  justify-content: space-between;
  font-size: 24px;
  z-index: 100;
}

.modal-body {
  position: relative;
  padding: 5px 10px 10px;
  font-size: 24px;
  z-index: 100;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 28px;
  padding: 5px 15px 5px;
  cursor: pointer;
  font-weight: bold;
  color: #fc0000;
  background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.7s ease;
}
</style>
