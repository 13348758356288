<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <ModalActivity
    :img_url="aURL"
    :theTitle="aTitle"
    v-show="isModalVisible"
    @close="closeModal"
  />
  <div id="curriculum_content">
    <span id="back">
      <router-link to="/curriculum"
        ><img
          alt="Edit"
          src="../../../assets/images/arrow_black_left.png"
        />Back to Chapters</router-link
      ></span
    >
  </div>
  <div id="curriculum_content">
    <div id="chp_content">
      <h2>Teacher Notes - Chapter 2</h2>
      <strong>CS Standard:</strong> Model the way programs store and manipulate
      data by using numbers or other symbols to represent information. (CSTA
      1A-AP-09)
      <p><strong>Lesson Flow</strong></p>
      <p>Warm-up Video: Coming Soon</p>
      <p>
        Warm Up: Give students examples of hand gestures that have a common
        meaning (e.g. Call me, Stop, Peace). Discuss how computers have their
        own symbols to represent data. (5 minutes)
      </p>
      <p>
        Online Work: Students read chapter two and complete the chapter reading
        and computing activities to earn three stars. (25 minutes)
      </p>
      <p>
        Offline Work: Students complete
        <a href="wrkbk/ch2.pdf" target="_blank">Chapter Two</a> in their
        CompuTales workbook. (20 minutes)
      </p>
      <p>
        Closing: Ask students to give examples of non-verbal gestures that have
        a established meaning. (5 minutes)
      </p>
      <p>
        Literacy Discussion: Why does Martin want to go home? (5 minutes)
      </p>
      <p><strong>Resources</strong></p>
      <p>
        Story Summary: Martin wakes up in Computer Land and meets his first
        friend Squeak (the computer mouse). Martin is impressed with Computer
        Land, but wants to go home. Squeak agrees to Take Martin to meet
        MotherBoard who can help him.
      </p>
      <p>
        Sample Computing Activities:
        <a
          id="modal_link"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp2_2',
              'Representing Information'
            )
          "
          >One</a
        >
      </p>
      <p>
        Sample Reading Activities:
      </p>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import ModalActivity from '@/components/Modal-Activity.vue';
export default {
  name: 'NotesTwo',
  components: {
    'top-header': TopHeader,
    ModalActivity
  },
  data() {
    return {
      isModalVisible: false,
      aURL: '',
      aTitle: ''
    };
  },
  methods: {
    showModal(theURL, theTitle) {
      this.isModalVisible = true;
      this.aURL = theURL;
      this.aTitle = theTitle;
    },
    closeModal() {
      this.isModalVisible = false;
      this.aURL = '';
    }
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

#curriculum_content {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 1100px;
  text-align: left;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 10px;
}

#chp_content {
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-size: 18px;
}

#back {
  width: 50%;
  float: left;
  text-align: left;
}

a {
  color: blue;
  text-align: center;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

h2 {
  text-align: center;
  font-size: 20px;
  padding: 0px;
}
</style>
