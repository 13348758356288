<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content">
    <span>
      <router-link
        :to="{
          name: 'roster',
          query: { classID: classID, class: className }
        }"
        ><img alt="Edit" src="../../assets/images/arrow_black_left.png" />Back
        to {{ className }} Roster</router-link
      ></span
    >
    <div v-if="error" class="error">{{ error }}</div>
    <form @submit.prevent="pressed">
      <div class="text">
        <input type="text" v-model="student_name" placeholder="Student Name" />
      </div>

      <select v-model="classSelected">
        <option v-for="aClass in classes" :key="aClass.id" :value="aClass.id">
          {{ aClass.name }}
        </option>
      </select>

      <span v-if="paid == 'yes'" class="container2">Language:</span>
      <label v-if="paid == 'yes'" class="container3"
        >English
        <input
          type="radio"
          name="langradio"
          value="English"
          v-model="language"
          :checked="language == 'English'"
        />
        <span class="checkmark"></span>
      </label>
      <label v-if="paid == 'yes'" class="container3"
        >Spanish
        <input
          type="radio"
          name="langradio"
          value="Spanish"
          v-model="language"
          :checked="language == 'Spanish'"
        />
        <span class="checkmark"></span>
      </label>

      <br /><br />
      <n-button @click="handleClick" ghost type="primary" color="blue"
        >Update Student</n-button
      >
    </form>
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';
import { NButton } from 'naive-ui';

export default {
  name: 'EditStudent',
  components: {
    'top-header': TopHeader,
    'n-button': NButton
  },
  methods: {
    async handleClick() {
      var db = firebase.firestore();
      try {
        if (this.student_name.length == 0) throw 'Please enter a name';
        if (this.student_name.length > 14)
          throw 'Name must be 14 or less characters';
        else {
          await db
            .collection('users')
            .doc(this.docID)
            .set(
              {
                name: this.student_name,
                //language: this.language,
                classID: this.classSelected
              },
              { merge: true }
            );

          var first1 = '', name1 = '', user1 = '';
          var rosterRef = await db.collection('classes').doc(this.classID);
          await db
            .runTransaction(transaction => {
              return transaction.get(rosterRef).then(rosterDoc => {
                if (!rosterDoc.exists) {
                  throw 'Document does not exist!';
                }
                var newRoster = rosterDoc.data().roster;
                const objIndex = newRoster.findIndex(
                    obj => obj.user === this.student_email
                  );
                if(this.originalClass == this.classSelected) {     
                  newRoster[objIndex].name = this.student_name;  
                }
                else {
                  first1 = newRoster[objIndex].first;
                  name1 = newRoster[objIndex].name;
                  user1 = newRoster[objIndex].user;
                  if (objIndex > -1) { 
                    newRoster.splice(objIndex, 1);
                  }
                }   
                transaction.update(rosterRef, { roster: newRoster });
              });
            })
            .then(() => {
              console.log('Transaction successfully committed!');
            })
            .catch(error => {
              console.log('Transaction failed: ', error);
            });

            if(this.originalClass != this.classSelected) {
              var rosRef = await db.collection('classes').doc(this.classSelected);
              await db
              .runTransaction(transaction => {         
                return transaction.get(rosRef).then(rosDoc => {
                  var newRos = [];
                  if (!rosDoc.exists) {
                    throw 'Document does not exist!';
                  }
                  if(rosDoc.data().roster)
                    newRos = rosDoc.data().roster;
                  newRos.push({first: first1, name: name1, user: user1});
                  transaction.update(rosRef, { roster: newRos });
                });
              })
              .then(() => {
                console.log('Transaction successfully committed!');
              })
              .catch(error => {
                console.log('Transaction failed: ', error);
              });
            }

          this.$router.replace({
            name: 'roster',
            query: { classID: this.classID, class: this.className }
          });
        }
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    }
  },
  async mounted() {
    var db = firebase.firestore();
    try {
      var classesRef = await db.collection('classes');
      var theClasses = await classesRef
        .where('code', '==', this.$store.state.user.code)
        .orderBy('name');
      let snapshot = await theClasses.get();
      snapshot.forEach(doc => {
        this.classes.push({
          name: doc.data().name,
          id: doc.id
        });
      });

      this.dataReady = true;
    } catch (err) {
      console.log(err);
    }
  },
  data() {
    return {
      error: '',
      classes: [],
      classSelected: this.$route.query.classID,
      originalClass: this.$route.query.classID,
      student_name: this.$route.query.name,
      student_email: this.$route.query.email,
      docID: this.$route.query.docID,
      className: this.$route.query.class,
      classID: this.$route.query.classID,
      //language: this.$route.query.language,
      paid: 'no'
    };
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

.error {
  color: red;
  font-size: 18px;
}

select,
input {
  width: 400px;
  padding: 10px;
  margin: 10px;
  font-size: 20px;
}

button {
  width: 150px;
  height: 35px;
  font-size: 18px;
}

#content {
  text-align: center;
  margin: auto;
  padding: 10px;
}

a {
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

/* Customize the label (the container) */
.container3 {
  display: inline-flex;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container2 {
  display: inline-flex;
  position: relative;
  padding-left: 35px;
  padding-right: 10px;
  margin-bottom: 12px;
  margin-top: 18px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container3 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container3:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container3 input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container3 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container3 .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
</style>
