<template>
  <public-header></public-header>
  <ModalWorkbook
    :img_url="aURL"
    :theTitle="aTitle"
    v-show="isModalVisible"
    @close="closeModal"
  />
  <div id="spacer"></div>
  <div id="content">
    <div id="div3">
      <div id="content3">
        <h1 id="mainhead">
          Bring Computational Thinking to your School!
        </h1>
        <div class="container">
          <div class="box">
            <p id="descript2">
              Starter kit of lesson plans to for each concept
            </p>
            <ul class="features">
              <li class="features">
                18 interactive chapters
              </li>
              <li class="features">
                90 computational activities
              </li>
              <li class="features">
                72 literacy activities
              </li>
              <li class="features">
                Pre-reader friendly
              </li>
              <li class="features">
                Access to students' saved drawings
              </li>
              <li class="features">
                Aligned to CSTA K-2 standards
              </li>
              <li class="features">
                Unlimited classes and students
              </li>
              <li class="features">
                Progress and usage reports
              </li>
            </ul>
            <div>
              <router-link class="button2" to="/register"
                >Get Started</router-link
              >
            </div>
          </div>
          <div class="box overlay">
            <p>Teacher Kickstarter</p>
            <p id="descript2">
              Great for exposing your youngest learners to compuational thinking
            </p>
            <p>Free Forever</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PublicHeader from '@/components/Public-Header';
import ModalWorkbook from '@/components/Modal-Workbook.vue';
export default {
  name: 'StoreView',
  components: {
    'public-header': PublicHeader,
    ModalWorkbook
  },
  data() {
    return {
      isModalVisible: false,
      aURL: '',
      aTitle: ''
    };
  },
  methods: {
    showModal(theURL, theTitle) {
      this.isModalVisible = true;
      this.aURL = theURL;
      this.aTitle = theTitle;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
};
</script>

<style scoped>
@media only screen and (min-width: 901px) {
  div.div1 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  div.div1 {
    font-size: 14px;
  }
}

@media (min-width: 901px) {
  h1 {
    font-size: 36px;
  }
}

@media (max-width: 900px) {
  h1 {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  div#connectinfo {
    display: none;
  }
}

@media (max-width: 1300px) {
  div#storeinfo {
    display: none;
  }
}

@media (max-width: 1000px) {
  div#educatorinfo {
    display: none;
  }
}

@media (max-width: 800px) {
  div#aboutinfo {
    display: none;
  }
}

div.div1 {
  margin-top: 86px;
  text-align: center;
  display: inline-block;
  width: 70%;
  margin-bottom: 200px;
  font-size: 22px;
}

.footer {
  background-color: rgb(50, 50, 50);
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 26px;
}

#content {
  text-align: center;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}

#content4 {
  width: 70%;
  color: white;
  padding-top: 20px;
  margin: auto;
}

#brandinfo {
  font-size: 14px;
  width: 320px;
  float: left;
  text-align: left;
  line-height: 1.6;
}

#aboutinfo {
  width: 140px;
  text-align: left;
  line-height: 1.6;
  margin-left: 50px;
  display: inline-block;
}

#educatorinfo {
  width: 140px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#storeinfo {
  width: 140px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#connectinfo {
  width: 220px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#header-heading {
  font-size: 16px;
  padding: 0;
  margin-bottom: 0px;
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
  margin-left: 8px;
  font-size: 14px;
}

#descript1 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: left;
  display: inline-block;
  max-width: 1280px;
  line-height: 1.8;
  margin-top: 20px;
}

#descript2 {
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 0px;
}

#checkhead {
  font-size: 32px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0px;
}

.link {
  font-weight: 500;
  color: blue;
  list-style: none;
  text-decoration: none;
  font-size: 22px;
  transition: 0.5s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}

.link-footer {
  font-weight: 500;
  color: white;
  list-style: none;
  text-decoration: none;
  font-size: 18px;
  transition: 0.5s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}

.link:hover {
  color: blue;
  border-color: #00afea;
}

.link-footer:hover {
  color: white;
  border-color: #00afea;
}

ul.features {
  float: bottom;
  margin-left: 0;
  padding-right: 0;
  margin-top: 20px;
  text-align: left;
}

li.features {
  padding: 0 0 0 0px;
  margin: 0px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  line-height: 26px;
}

#content3 {
  max-width: 1450px;
}

#mainhead {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 28px;
  padding-top: 120px;
  padding-bottom: 0px;
  margin: 30px;
}

#div3 {
  display: grid;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  margin: 0px;
  margin-bottom: 200px;
}

#div4 {
  display: grid;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 30px;
  margin: 0px;
  margin-bottom: 200px;
}

.button2 {
  background-color: #4ca44a;
  color: white;
  padding: 9px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 12px;
  margin-left: 20px;
  margin-top: 10px;
  border: 1px solid #4ca44a;
}

.button1 {
  background-color: #4873ac;
  color: white;
  padding: 9px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 12px;
  margin-left: 20px;
  margin-top: 10px;
  border: 1px solid #4873ac;
}

.button3 {
  background-color: #d33e6d;
  color: white;
  padding: 9px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 12px;
  margin-left: 20px;
  margin-top: 10px;
  border: 1px solid #d33e6d;
}

a.button1:hover,
a.button1.router-link-exact-active {
  background-color: blue;
  font-size: 20px;
  color: #fff;
}

a.button2:hover,
a.button2.router-link-exact-active {
  background-color: green;
  font-size: 20px;
  color: #fff;
}

a.button3:hover,
a.button3.router-link-exact-active {
  background-color: rgb(186, 8, 91);
  font-size: 20px;
  color: #fff;
}

.container {
  width: 360px;
  height: 380px;
  position: relative;
  margin: 0 auto;
}

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 250px;
  border-radius: 30px;
  border: 2px solid #b0b0b0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.overlay {
  z-index: 9;
  margin-top: 0px;
  width: 356px;
  height: 220px;
  background-color: rgb(93, 209, 57);
  border: 0px;
  padding: 2px;
  margin-left: 2px;
  margin-top: 2px;
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.overlay2 {
  z-index: 9;
  margin-top: 0px;
  width: 356px;
  height: 220px;
  background-color: rgb(99, 183, 232);
  border: 0px;
  padding: 2px;
  margin-left: 2px;
  margin-top: 2px;
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
</style>
