<template>
  <public-header></public-header>
  <div id="spacer"></div>
  <div id="content">
    <div id="mytitle">
      Get started with a free teacher account!
    </div>
    <div v-if="error" class="error">{{ error }}</div>
    <form @submit.prevent="pressed">
      <div class="text">
        <input type="text" v-model="name" placeholder="First Name" />
      </div>
      <div class="text">
        <input type="text" v-model="last_name" placeholder="Last Name" />
      </div>
      <div class="email">
        <input type="email" v-model="email" placeholder="Email" />
      </div>
      <div class="password">
        <input type="password" v-model="password" placeholder="Password" />
      </div>
      <div class="password">
        <input
          type="password"
          v-model="confirm"
          placeholder="Confirm Password"
        />
      </div>
      <div style="padding-top: 10px;">
        <button type="submit">Get Started</button>
      </div>
    </form>
    <br />
    <hr
      style="height:2px; width:200px; border-width:0; color:green; background-color:green"
    />
    <br />
    <img
      alt="Google Login"
      src="../assets/images/btn_google_login.png"
      v-on:click="googleLogin"
    />
  </div>
</template>

<script>
import { firebase } from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import PublicHeader from '@/components/Public-Header';

export default {
  name: 'RegisterView',
  components: { 'public-header': PublicHeader },
  methods: {
    async pressed() {
      var db = firebase.firestore();
      var theCode = this.getCode();
      try {
        if (
          this.name.length == 0 ||
          this.last_name.length == 0 ||
          this.email.length == 0 ||
          this.password.length == 0 ||
          this.confirm.length == 0
        )
          throw 'Please complete all fields';
        if (this.password != this.confirm) throw 'Passwords must match';
        const user = await firebase
          .auth()
          .createUserWithEmailAndPassword(this.email, this.password);
        await db
          .collection('users')
          .doc(user.user.uid)
          .set(
            {
              name: this.name,
              last_name: this.last_name,
              email: this.email,
              code: theCode,
              teacher: 'y',
              ch1: 'done',
              ch2: 'done',
              ch3: 'done',
              ch4: 'done',
              ch5: 'done',
              ch6: 'done',
              ch7: 'done',
              ch8: 'done',
              ch9: 'done',
              ch10: 'done',
              ch11: 'done',
              ch12: 'done',
              ch13: 'done',
              ch14: 'done',
              ch15: 'done',
              ch16: 'done',
              ch17: 'done',
              ch18: 'done'
            },
            { merge: true }
          );

        await db.collection('mail').add({
          to: this.email,
          bcc: 'support@computales.com',
          message: {
            subject: 'CompuTales Registration',
            html:
              this.name +
              ',<br/><br/>Welcome to CompuTales!' +
              '<br/><br/>Thank you for helping to expose your students ' +
              'to the exciting world of computer science through interactive stories.' +
              'Click <a href="https://computales.com/instructions">here</a> for the quick ' +
              'set-up guide.' +
              '<br/><br/>If you have any questions, please contact us at support@computales.com ' +
              '<br/><br/>Thank you,' +
              '<br/>CompuTales Support Team'
          }
        });

        var aUser1 = {
          id: user.user.uid,
          name: this.name,
          code: theCode,
          email: this.email
        };
        this.$store.commit('STORE_USER', aUser1);
        this.$router.replace({ name: 'classes' });
      } catch (err) {
        console.log(err);
        if (err.code == 'auth/invalid-email')
          this.error = 'Use proper email format.';
        else if (err.code == 'auth/email-already-in-use')
          this.error = 'The email has already been used.';
        else if (err.code == 'auth/weak-password')
          this.error = 'Password should be at least 6 characters.';
        else this.error = err;
      }
    },
    getCode() {
      var randomChars =
        '0123456789567721abcdefghijklmnopqrstuvwxyz0123456789348';
      var result = '';
      for (var i = 0; i < 5; i++) {
        result += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }
      return result;
    },
    async googleLogin() {
      var theCode = this.getCode();
      var db = firebase.firestore();
      try {
        const provider = new firebase.auth.GoogleAuthProvider();
        var result = await firebase.auth().signInWithPopup(provider);
        var user = result.user;
        //////////////////////////////////////////////////
        var docRef = await db.collection('users').doc(user.uid);
        await docRef
          .get()
          .then(function(doc) {
            if (doc.exists) {
              theCode = doc.data().code;
            } else {
              docRef.set(
                {
                  name: user.displayName,
                  code: theCode,
                  email: user.email,
                  teacher: 'y',
                  ch1: 'done',
                  ch2: 'done',
                  ch3: 'done',
                  ch4: 'done',
                  ch5: 'done',
                  ch6: 'done',
                  ch7: 'done',
                  ch8: 'done',
                  ch9: 'done',
                  ch10: 'done',
                  ch11: 'done',
                  ch12: 'done',
                  ch13: 'done',
                  ch14: 'done',
                  ch15: 'done',
                  ch16: 'done',
                  ch17: 'done',
                  ch18: 'done'
                },
                { merge: true }
              );

              db.collection('mail').add({
                to: user.email,
                bcc: 'support@computales.com',
                message: {
                  subject: 'CompuTales Registration',
                  html:
                    user.displayName +
                    ',<br/><br/>Welcome to CompuTales!' +
                    '<br/><br/>Thank you for helping to expose your students ' +
                    'to the exciting world of computer science through interactive stories.' +
                    'Click <a href="https://computales.com/instructions">here</a> for the quick ' +
                    'set-up guide.' +
                    '<br/><br/>If you have any questions, please contact us at support@computales.com ' +
                    '<br/><br/>Thank you,' +
                    '<br/>CompuTales Support Team'
                }
              });
            }
          })
          .catch(function(error) {
            console.log('Error getting document:', error);
          });
        /////////////////////////////////////////////////////

        var aUser = {
          id: user.uid,
          name: user.displayName,
          code: theCode,
          email: user.email
        };
        this.$store.commit('STORE_USER', aUser);
        this.$router.replace({ name: 'classes' });
      } catch (err) {
        console.log(err);
        //this.error = err;
      }
    }
  },
  data() {
    return {
      name: '',
      last_name: '',
      email: '',
      password: '',
      confirm: '',
      error: ''
    };
  }
};
</script>

<style scoped>
#spacer {
  height: 100px;
}

.error {
  color: red;
  font-size: 18px;
}

input {
  width: 400px;
  padding: 15px;
  margin: 10px;
  font-size: 20px;
}

select {
  width: 435px;
  padding: 15px;
  margin: 10px;
  font-size: 20px;
}

button {
  width: 200px;
  height: 50px;
  font-size: 20px;
}

#content {
  text-align: center;
  margin-top: 20px;
}

#mytitle {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 12px;
}

#mysubtitle {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.link {
  color: rgb(0, 0, 0);
  list-style: none;
  text-decoration: none;
}

.link {
  font-size: 18px;
  transition: 0.3s ease all;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
}

.link:hover {
  color: blue;
  border-color: #00afea;
}

a.router-link-exact-active {
  color: blue;
  border-color: #00afea;
}
</style>
