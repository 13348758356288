<template>
  <public-header></public-header>
  <ModalActivity
    :img_url="aURL"
    :theTitle="aTitle"
    v-show="isModalVisible"
    @close="closeModal"
  />
  <ModalWorkbook
    :img_url="aURL"
    :theTitle="aTitle"
    v-show="isModalVisibleWorkbook"
    @close="closeModalWorkbook"
  />
  <div id="content">
    <div class="div1">
      <h1 id="mainhead">
        Integrate literacy and computational thinking!
      </h1>
      <div id="intro1">
        <p id="descript1">
          Take your students on a one-of-a-kind journey into computational
          thinking and literacy. Join Martin and his friends 
          as they embark on a journey through Computer Land, where each chapter is 
          filled with standards-aligned activities that develop computational thinking 
          and enhance literacy skills. Designed for grades K-2, CompuTales
          seamlessly integrates into your existing reading instruction while
          introducing the foundational skills that will set the stage for future
          exploration into coding and computer science.
        </p>
        <router-link class="button2a" to="/register">Get Started!</router-link>
      </div>
      <div id="vid1">
        <a
          href="#"
          @click="
            showModal(
              'https://www.youtube.com/embed/UaAEl-R-Ah4?rel=0&modestbranding=1'
            )
          "
        >
          <img
            id="Video_Preview"
            src="../assets/images/vid_preview.png"
            style="margin-top: 30px;"
          />
        </a>
      </div>
    </div> 
    <div id="div2">
      <div id="content2">
        <div id="card1">
          Designed using enagaing stories that reinforce early literacy.
          <span id="space3">
            <a
              class="link"
              href="#"
              @click="
                showModal(
                  'https://demobook.firebaseapp.com/?page2_1',
                  'Chapter 2'
                )
              "
            >
              Chapter Preview
            </a>
          </span>
          <span id="space1">
            - Literacy Activities -
            <a
              class="link"
              href="#"
              @click="
                showModal(
                  'https://demobook.firebaseapp.com/?read3_2',
                  'Phonics'
                )
              "
            >
              Phonics</a
            >
            |
            <a
              class="link"
              href="#"
              @click="
                showModal(
                  'https://demobook.firebaseapp.com/?read5_1',
                  'Sight Words'
                )
              "
            >
              Sight Words
            </a>
          </span>
          <span id="space4">
            <a
              class="link"
              href="#"
              @click="
                showModal(
                  'https://demobook.firebaseapp.com/?read6_3',
                  'Reading Comprehension'
                )
              "
            >
              Comprehension
            </a>
          </span>
        </div>
        <img
          id="gang"
          src="../assets/images/splash.png"
          style="margin-top: 30px;"
        />
        <div id="card2">
          Blended learning approach links an app and offline workbook to
          reinforce students' digital experience with hands-on learning.
          <span id="space2">
            <a
              class="link"
              href="#"
              @click="
                showModalWorkbook(
                  'https://portal.printingcenterusa.com/embed/embedflipbook.aspx?code=425133&title=CompuTales%20-%20First%20Grade&desc=CompuTales%20helps%20students%20to%20learn%20the%20building%20blocks%20of%20Computer%20%20%20%20%20%20%20%20%20Science%20with%20a%20fun%20and%20interactive%20story.%20Follow%20Martin%20and%20his%20friends%20as%20they%20explore%20Computer%20Land.%20Each%20chapter%20contains%20embedded%20activities%20to%20enhance%20literacy%20and%20computational%20thinking%20skills.%20Narrated%20for%20emerging%20readers%2C%20and%20aligned%20to%20CSTA%20standards%20for%20K-2.',
                  'First Grade Workbook'
                )
              "
              >Workbook Preview
            </a>
          </span>
        </div>
      </div>
    </div>
    <div id="div3">
      <h2>
        Sample Our Computational Thinking Activities!
      </h2>
      <div id="content3">
        <a
          id="modal_link"
          class="sample"
          href="#"
          @click="
            showModal('https://demobook.firebaseapp.com/?comp15_1', 'Coding')
          "
        >
          <img
            src="../assets/images/card_coding.png"
            style="float:left;padding:30px;"
          />
        </a>
        <a
          id="modal_link"
          class="sample"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp5_4',
              'Identifying Patterns'
            )
          "
        >
          <img
            src="../assets/images/card_patterns.png"
            style="float:left;padding:30px;"
          />
        </a>
        <a
          id="modal_link"
          class="sample"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp3_1',
              'Computer Hardware'
            )
          "
        >
          <img
            src="../assets/images/card_hardware.png"
            style="float:left;padding:30px;"
          />
        </a>
        <a
          id="modal_link"
          class="sample"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp1_1',
              'Everyday Algorithms'
            )
          "
        >
          <img
            src="../assets/images/card_algorithms.png"
            style="float:left;padding:30px;"
          />
        </a>
        <a
          id="modal_link"
          class="sample"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp2_2',
              'Representing Data'
            )
          "
        >
          <img
            src="../assets/images/card_represent_data.png"
            style="float:left;padding:30px;"
          />
        </a>
      </div>
      <div id="content3">
        <a
          id="modal_link"
          class="sample"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp4_2',
              'Collect Data'
            )
          "
        >
          <img
            src="../assets/images/card_collect_data.png"
            style="float:left;padding:30px;"
          />
        </a>
        <a
          id="modal_link"
          class="sample"
          href="#"
          @click="
            showModal('https://demobook.firebaseapp.com/?comp8_1', 'Debugging')
          "
        >
          <img
            src="../assets/images/card_debugging.png"
            style="float:left;padding:30px;"
          />
        </a>
        <a
          id="modal_link"
          class="sample"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp13_2',
              'Decompostion'
            )
          "
        >
          <img
            src="../assets/images/card_decomposition.png"
            style="float:left;padding:30px;"
          />
        </a>
        <a
          id="modal_link"
          class="sample"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp12_1',
              'Abstraction'
            )
          "
        >
          <img
            src="../assets/images/card_abstraction.png"
            style="float:left;padding:30px;"
          />
        </a>
        <a
          id="modal_link"
          class="sample"
          href="#"
          @click="
            showModal(
              'https://demobook.firebaseapp.com/?comp9_3',
              'Cybersecurity'
            )
          "
        >
          <img
            src="../assets/images/card_cyber.png"
            style="float:left;padding:30px;"
          />
        </a>
      </div>
    </div>
    <div class="footer">
      <div id="content4">
        <div id="brandinfo">
          <img alt="CompuTales Logo" src="../assets/images/logo3.png" /><br />
          CompuTales helps students to learn the building blocks of Computer
          Science with a fun and interactive story. Narrated for emerging
          readers, and aligned to CSTA standards for K-2.
        </div>
        <div id="aboutinfo">
          <p id="header-heading">
            <router-link :to="{ name: 'about' }" class="link-footer"
              >About</router-link
            >
          </p>
          <ul class="no-bullets">
            <li>Our Why</li>
            <li>How It Works</li>
            <li>Features</li>
          </ul>
        </div>
        <div id="educatorinfo">
          <p id="header-heading">
            <router-link :to="{ name: 'educators' }" class="link-footer"
              >Educators</router-link
            >
          </p>
          <ul class="no-bullets">
            <li>Curriculum</li>
            <li>Benefits</li>
            <li>FAQs</li>
          </ul>
        </div>
        <div id="storeinfo">
          <p id="header-heading">
            <router-link :to="{ name: 'store' }" class="link-footer"
              >Store</router-link
            >
          </p>
          <ul class="no-bullets">
            <li>Workbooks</li>
            <li>Subscribe</li>
            <li>&nbsp;</li>
          </ul>
        </div>
        <div id="connectinfo">
          <p id="header-heading">
            <router-link :to="{ name: 'contact' }" class="link-footer"
              >Connect With Us</router-link
            >
          </p>
          <ul class="no-bullets">
            <li>Contact</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
          </ul>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
// @ is an alias to /src
import PublicHeader from '@/components/Public-Header';
import ModalActivity from '@/components/Modal-Activity.vue';
import ModalWorkbook from '@/components/Modal-Workbook.vue';
export default {
  name: 'HomeView',
  components: {
    'public-header': PublicHeader,
    ModalActivity,
    ModalWorkbook
  },
  data() {
    return {
      isModalVisible: false,
      isModalVisibleWorkbook: false,
      aURL: '',
      aTitle: ''
    };
  },
  methods: {
    showModal(theURL, theTitle) {
      this.isModalVisible = true;
      this.aURL = theURL;
      this.aTitle = theTitle;
    },
    showModalWorkbook(theURL, theTitle) {
      this.isModalVisibleWorkbook = true;
      this.aURL = theURL;
      this.aTitle = theTitle;
    },
    closeModal() {
      this.isModalVisible = false;
      this.aURL = '';
    },
    closeModalWorkbook() {
      this.isModalVisibleWorkbook = false;
      this.aURL = '';
    }
  }
};
</script>

<style scoped>
@media only screen and (min-width: 901px) {
  div.div1 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  div.div1 {
    font-size: 14px;
  }
}

@media (min-width: 901px) {
  h1 {
    font-size: 36px;
  }
}

@media (max-width: 900px) {
  h1 {
    font-size: 24px;
  }
}

@media (min-width: 901px) {
  h2 {
    font-size: 32px;
  }
}

@media (max-width: 900px) {
  h2 {
    font-size: 24px;
  }
}

@media (max-width: 900px) {
  button.button3a {
    display: none;
  }
  div#card1 {
    width: 200px;
  }
  div#card2 {
    width: 200px;
  }
}

@media (max-width: 1000px) {
  img#gang {
    display: none;
  }
}

@media (max-width: 1150px) {
  img#Video_Preview {
    display: none;
  }
}

@media (max-width: 1600px) {
  div#connectinfo {
    display: none;
  }
}

@media (max-width: 1300px) {
  div#storeinfo {
    display: none;
  }
}

@media (max-width: 1000px) {
  div#educatorinfo {
    display: none;
  }
}

@media (max-width: 800px) {
  div#aboutinfo {
    display: none;
  }
}

@media (max-width: 1000px) {
  a#modal_link {
    pointer-events: none;
  }
}

@media (max-width: 1000px) {
  a.link {
    pointer-events: none;
  }
}

div.div1 {
  margin-top: 86px;
  text-align: center;
  display: inline-block;
  padding-bottom: 30px;
}

#div2 {
  background-color: rgb(193, 193, 193);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 35px;
}

#div3 {
  padding-top: 0px;
  display: inline-grid;
  margin-bottom: 200px;
}

.footer {
  background-color: rgb(50, 50, 50);
  clear: both;
  position: relative;
  height: 200px;
  margin-top: -200px;
}

#content {
  text-align: center;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}

#content2 {
  width: 1100px;
}

#content3 {
  max-width: 1130px;
  padding-left: 20px;
}

#content4 {
  width: 70%;
  color: white;
  padding-top: 20px;
  margin: auto;
}

#brandinfo {
  font-size: 14px;
  width: 320px;
  float: left;
  text-align: left;
  line-height: 1.6;
}

#aboutinfo {
  width: 140px;
  text-align: left;
  line-height: 1.6;
  margin-left: 50px;
  display: inline-block;
}

#educatorinfo {
  width: 140px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#storeinfo {
  width: 140px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#connectinfo {
  width: 220px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#header-heading {
  font-size: 16px;
  padding: 0;
  margin-bottom: 0px;
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
  margin-left: 8px;
  font-size: 14px;
}

#mainhead {
  font-weight: bold;
  padding-top: 40px;
  padding-bottom: 10px;
  margin: 10px;
}

#descript1 {
  text-align: left;
  display: flex;
  line-height: 1.8;
  margin-top: 20px;
  margin-right: 30px;
}

#checkhead {
  font-size: 32px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0px;
}

a.sample:hover img {
  transform: scale(1.1);
}

#card1 {
  font-size: 20px;
  padding: 10px;
  width: 220px;
  height: 240px;
  float: left;
  background-color: rgb(195, 224, 255);
  border-radius: 25px;
  border: 4px solid #0163a5;
  line-height: 1.5;
}

#intro1 {
  font-size: 20px;
  padding-left: 20px;
  max-width: 610px;
  float: left;
  line-height: 1.5;
}

#card2 {
  font-size: 20px;
  padding: 10px;
  width: 220px;
  height: 240px;
  float: right;
  background-color: rgb(195, 224, 255);
  border-radius: 25px;
  border: 4px solid #0163a5;
  line-height: 1.5;
}

#vid1 {
  float: right;
}

#space1 {
  display: block;
  text-align: center;
  padding-top: 10px;
}

#space2 {
  display: block;
  text-align: center;
  padding: 10px;
}

#space3 {
  display: block;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

#space4 {
  display: block;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.link {
  font-weight: 500;
  color: blue;
  list-style: none;
  text-decoration: none;
  font-size: 20px;
  transition: 0.5s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}

.link-footer {
  font-weight: 500;
  color: white;
  list-style: none;
  text-decoration: none;
  font-size: 18px;
  transition: 0.5s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}

.link:hover {
  color: blue;
  border-color: #00afea;
}

.link-footer:hover {
  color: white;
  border-color: #00afea;
}

.button2a {
  background-color: #4ca44a;
  color: white;
  padding: 12px 26px;
  width: 170px;
  text-decoration: none;
  display: inline;
  font-size: 22px;
  border-radius: 25px;
  margin-left: 24px;
  margin-right: 24px;
  border: 1px solid #4ca44a;
}

.button3a {
  background-color: #3b6da5;
  color: white;
  padding: 12px 26px;
  width: 170px;
  text-decoration: none;
  display: inline;
  font-size: 22px;
  border-radius: 25px;
  margin-left: 24px;
  margin-right: 24px;
  border: 1px solid #3b6da5;
}

.button2a:hover,
.button2a.router-link-exact-active {
  background-color: green;
  font-size: 22px;
  color: #fff;
  cursor: pointer;
}

.button3a:hover,
.button3a.router-link-exact-active {
  background-color: rgb(0, 34, 128);
  font-size: 22px;
  color: #fff;
  cursor: pointer;
}
</style>
