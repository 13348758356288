<template>
  <public-header></public-header>
  <div id="spacer"></div>
  <div id="content">
    <p>
      Please check your email for password reset instructions.
    </p>
  </div>
</template>

<script>
import PublicHeader from '@/components/Public-Header';

export default {
  name: 'ResetComplete',
  components: { 'public-header': PublicHeader }
};
</script>

<style scoped>
#spacer {
  height: 100px;
}

.error {
  color: red;
  font-size: 18px;
}
input {
  width: 400px;
  padding: 15px;
  margin: 10px;
  font-size: 20px;
}

button {
  width: 400px;
  height: 50px;
  font-size: 100%;
}

#content {
  text-align: center;
}

p {
  font-size: 20px;
  color: blue;
}
</style>
