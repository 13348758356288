<template>
  <public-header></public-header>
  <div id="spacer"></div>
  <div id="content">
    <h2>Are you a teacher or a student?</h2>
    <div id="boxcontainer">
      <router-link to="login">
        <img
          alt="Teacher Login"
          src="../assets/images/teach_login.png"
          style="float:left"
        />
      </router-link>
      <a href="https://book.computales.com" target="_blank">
        <img
          alt="Student Login"
          src="../assets/images/student_login.png"
          style="float:right"
        />
      </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PublicHeader from '@/components/Public-Header';
export default {
  name: 'LogSelect',
  components: {
    'public-header': PublicHeader
  }
};
</script>

<style scoped>
#spacer {
  height: 80px;
}

#content {
  text-align: center;
  margin-top: 40px;
  font-size: 16px;
}

#boxcontainer {
  padding-top: 10px;
  margin: auto;
  width: 540px;
  height: 200;
}
</style>
