<template>
  <public-header></public-header>
  <div id="content">
    <div id="mytitle">
      Request Quote
    </div>
    <div v-if="error" class="error">{{ error }}</div>
    <div v-if="success" class="success">{{ success }}</div>

    <form v-show="!success" @submit.prevent="pressed">
      <div class="text">
        <input type="text" v-model="name" placeholder="Name" />
      </div>
      <div class="email">
        <input type="email" v-model="email" placeholder="Email" />
      </div>
      <div class="text">
        <input
          type="text"
          v-model="institution"
          placeholder="Name of Institution"
        />
      </div>
      <div class="text">
        <select v-model="studs">
          <option disabled value="">Number of Workbooks</option>
          <option value="0">0</option>
          <option value="30">30</option>
          <option value="60">60</option>
          <option value="90">90</option>
          <option value="120">120</option>
          <option value="150">150</option>
          <option value="180">180</option>
          <option value="210">210</option>
          <option value="240">240</option>
          <option value="270">270</option>
          <option value="300">300</option>
          <option value="330">330</option>
        </select>
      </div>
      <div class="text">
        <textarea
          v-model="message"
          rows="4"
          cols="80"
          placeholder="Anything else we should know?"
        >
        </textarea>
      </div>
      <div style="padding-top: 10px;">
        <button type="submit">Send Request</button>
      </div>
    </form>
  </div>
</template>

<script>
import { firebase } from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import PublicHeader from '@/components/Public-Header';

export default {
  name: 'QuoteView',
  components: { 'public-header': PublicHeader },
  methods: {
    async pressed() {
      var db = firebase.firestore();
      try {
        if (
          this.name.length == 0 ||
          this.email.length == 0 ||
          this.institution.length == 0 ||
          this.studs.length == 0
        )
          throw 'Please complete all fields';
        await db.collection('mail').add({
          to: 'support@computales.com',
          message: {
            subject: 'CompuTales Quote Request',
            html:
              'From: ' +
              this.name +
              '<br><br>' +
              'Email: ' +
              this.email +
              '<br><br>' +
              'Institution: ' +
              this.institution +
              '<br><br>' +
              'Number of workbooks: ' +
              this.studs +
              '<br><br>' +
              this.message
          }
        });
        this.success =
          'Thank you for your quote request. We will get back to you shortly!';
        this.error = '';
      } catch (err) {
        this.error = err;
      }
    }
  },
  data() {
    return {
      name: '',
      email: '',
      institution: '',
      studs: '',
      message: '',
      error: '',
      success: ''
    };
  }
};
</script>

<style scoped>
#spacer {
  height: 100px;
}

.error {
  color: red;
  font-size: 18px;
}

.success {
  color: rgb(4, 130, 0);
  font-size: 18px;
}

input {
  width: 400px;
  padding: 15px;
  margin: 10px;
  font-size: 20px;
}

select {
  width: 435px;
  padding: 15px;
  margin: 10px;
  font-size: 20px;
}

textarea {
  width: 400px;
  padding: 15px;
  margin: 10px;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

button {
  width: 200px;
  height: 50px;
  font-size: 20px;
}

#content {
  text-align: center;
  margin-top: 120px;
}

#mytitle {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}

@media only screen and (min-width: 901px) {
  div.div1 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  div.div1 {
    font-size: 14px;
  }
}

@media (min-width: 901px) {
  h1 {
    font-size: 36px;
  }
}

@media (max-width: 900px) {
  h1 {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  div#connectinfo {
    display: none;
  }
}

@media (max-width: 1300px) {
  div#storeinfo {
    display: none;
  }
}

@media (max-width: 1000px) {
  div#educatorinfo {
    display: none;
  }
}

@media (max-width: 800px) {
  div#aboutinfo {
    display: none;
  }
}

div.div1 {
  margin-top: 86px;
  text-align: center;
  display: inline-block;
}

.footer {
  background-color: rgb(50, 50, 50);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 26px;
}

#content {
  text-align: center;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}

#content4 {
  width: 70%;
  color: white;
  padding-top: 20px;
  margin: auto;
}

#brandinfo {
  font-size: 14px;
  width: 320px;
  float: left;
  text-align: left;
  line-height: 1.6;
}

#aboutinfo {
  width: 140px;
  text-align: left;
  line-height: 1.6;
  margin-left: 50px;
  display: inline-block;
}

#educatorinfo {
  width: 140px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#storeinfo {
  width: 140px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#connectinfo {
  width: 220px;
  display: inline-block;
  text-align: left;
  line-height: 1.6;
  margin-left: 30px;
}

#header-heading {
  font-size: 16px;
  padding: 0;
  margin-bottom: 0px;
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
  margin-left: 8px;
  font-size: 14px;
}

.link {
  font-weight: 500;
  color: blue;
  list-style: none;
  text-decoration: none;
  font-size: 20px;
  transition: 0.5s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}

.link-footer {
  font-weight: 500;
  color: white;
  list-style: none;
  text-decoration: none;
  font-size: 18px;
  transition: 0.5s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}

.link:hover {
  color: blue;
  border-color: #00afea;
}

.link-footer:hover {
  color: white;
  border-color: #00afea;
}

div.div1 {
  margin-top: 100px;
  text-align: center;
  display: inline-block;
  width: 70%;
}
</style>
