<template>
  <public-header></public-header>
  <div id="spacer"></div>
  <div id="content">
    <div v-if="error" class="error">{{ error }}</div>
    <form @submit.prevent="pressed">
      <div class="email">
        <input type="email" v-model="email" placeholder="Email" />
      </div>
      <button type="submit">Send Password Reset Link</button>
    </form>
  </div>
</template>

<script>
import { firebase } from '@firebase/app';
import '@firebase/auth';
import PublicHeader from '@/components/Public-Header';

export default {
  name: 'ResetPW',
  components: { 'public-header': PublicHeader },
  methods: {
    async pressed() {
      try {
        await firebase.auth().sendPasswordResetEmail(this.email); // test commit
        this.$router.replace({ name: 'resetcomplete' });
      } catch (err) {
        if (err.code == 'auth/user-not-found')
          this.error = 'The email does not have a user associated with it.';
        else this.error = err.message;
        console.log(err);
      }
    }
  },
  data() {
    return {
      email: '',
      error: ''
    };
  }
};
</script>

<style scoped>
#spacer {
  height: 100px;
}

.error {
  color: red;
  font-size: 18px;
}
input {
  width: 400px;
  padding: 15px;
  margin: 10px;
  font-size: 20px;
}

button {
  width: 300px;
  height: 50px;
  font-size: 20px;
}

#content {
  text-align: center;
}
</style>
