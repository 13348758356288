<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="curriculum_content">
    <div id="chp_content">
      <router-link to="/notesoverview"
        >Overview<img alt="Edit" src="../../assets/images/arrow_black_right.png"
      /></router-link>
    </div>

    <div id="left_list">
      <div id="chp_content">
        <router-link to="/notes1"
          >Chapter 1 - Everyday Algorithms<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes2"
          >Chapter 2 - Representing Data<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes3"
          >Chapter 3 - Computer Hardware<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes4"
          >Chapter 4 - Collecting Data<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes5"
          >Chapter 5 - Identifying Patterns<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes6"
          >Chapter 6 - Identifying Patterns<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes7"
          >Chapter 7 - Decomposition<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes8"
          >Chapter 8 - Debugging<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes9"
          >Chapter 9 - Store and Manipulate Data<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
    </div>
    <div id="right_list">
      <div id="chp_content">
        <router-link to="/notes10"
          >Chapter 10 - Sequential Programing<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes11"
          >Chapter 11 - Sequential Programing<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes12"
          >Chapter 12 - Abstraction<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes13"
          >Chapter 13 - Decompostion<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes14"
          >Chapter 14 - Computer Hardware<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes15"
          >Chapter 15 - Sequential Programing<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes16"
          >Chapter 16 - Sequential Programing<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes17"
          >Chapter 17 - Fixing Computer Issues<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
      <div id="chp_content">
        <router-link to="/notes18"
          >Chapter 18 - Representing Data<img
            alt="Edit"
            src="../../assets/images/arrow_black_right.png"
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
export default {
  name: 'CurriculumView',
  components: {
    'top-header': TopHeader
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

#curriculum_content {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  max-width: 1000px;
  text-align: left;
  font-size: 18px;
}

#chp_content {
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-size: 18px;
  padding: 10px;
}

#left_list {
  float: left;
  text-align: left;
  padding-right: 40px;
}

#right_list {
  text-align: left;
  float: left;
}

a {
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}
</style>
