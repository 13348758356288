<template>
  <div id="content">
    <img alt="CompuTales Logo" src="../assets/images/logo2.png" />
    <h2>Curriculum Guide</h2>
    <div id="instruct">
      <p>
        Welcome to CompuTales, a unique journey into computational thinking and
        reading for students in Kinder – 2nd grade. <br /><br />CompuTales is
        designed to fit into your reading curriculum, while introducing students
        to the computational thinking skills that will set the foundation for
        later exploration into coding and computer science. CompuTales can be
        completed one chapter per week, throughout the school year. Each chapter
        and the accompanying Computation, Reading and Writing activities can be
        completed in approximately one hour.
      </p>
      <p>
        <b>Step 1:</b>&nbsp; Provide a device for each student. Ask students to
        go to the CompuTales app and press the Start button. <br /><br />
        During first login, students will be asked to choose a picture password.
      </p>
      <p>
        <b>Step 2:</b>&nbsp; Assign the chapter to be completed for the week.
        Every activity and chapter in CompuTales is narrated, so students can
        complete individually, in small groups, or whole group. <br /><br />
        Note: students must complete chapters in order, including all
        activities, in order to proceed.
      </p>
      <p>
        <b>Step 3:</b>&nbsp; Students begin by reading the assigned chapter.
        Play buttons are provided to assist early readers.
      </p>
      <p>
        <b>Step 4:</b>&nbsp; After reading the chapter, students access the
        computing, reading, and writing activities from the table of contents by
        selecting the chapter again.
      </p>
      <p>
        <b>Step 5:</b>&nbsp; As each set of activities is completed, a star is
        placed on the chapter. When a student receives all three stars, the
        chapter is complete.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurriculumGuide'
};
</script>

<style scoped>
.error {
  color: red;
  font-size: 18px;
}
input {
  width: 400px;
  padding: 15px;
  margin: 10px;
  font-size: 20px;
}

button {
  width: 400px;
  height: 50px;
  font-size: 100%;
}

#content {
  text-align: center;
}

#instruct {
  width: 750px;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-size: 20px;
}
</style>
